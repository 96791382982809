import React from "react";
import { Modal, ModalBody } from "reactstrap";

import crossIcon from "../../assets/img/icons/cross_black.svg";
import pauseIcon from "../../assets/pause-subscription.svg";
import { openModal } from "./Paddle";

function CancelModal({
  modal,
  setModal,
  cancelURL,
  managePauseSubscription,
  isPaused,
}) {
  const toggle = () =>
    setModal({
      cancelModal: !modal.cancelModal,
    });

  const OpenPaddleModal = (url) => {
    openModal(url);
    toggle();
  };

  return (
    <div>
      <Modal
        isOpen={modal.cancelModal}
        toggle={toggle}
        backdrop={true}
        keyboard={true}
        centered={true}
        size="lg">
        <img
          src={crossIcon}
          alt="cross"
          className="cross-icon"
          onClick={toggle}
        />

        <ModalBody>
          <div className="cancel-modal-body">
            <p>
              {isPaused
                ? "Your subscription is paused"
                : "Consider pausing your subscription instead of canceling"}
            </p>
            <p>
              {isPaused
                ? "If you want you can resume your subscription and keep using Groupboss."
                : "Do you know? You can take a break without canceling your subscription and resume once you decide."}
            </p>
            <img
              src={pauseIcon}
              alt=""
              style={{
                width: "120px",
                height: "120px",
              }}
            />
            <div className="modal-container-box">
              <button
                className="pause-button"
                onClick={() => {
                  managePauseSubscription({
                    pause: isPaused ? false : true,
                  });
                  toggle();
                }}>
                {!isPaused ? "Pause Subscription" : "Resume Subscription"}
              </button>
              <button
                className="cancel-button"
                onClick={() => OpenPaddleModal(cancelURL)}>
                Cancel Subscription Anyway!
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CancelModal;
