import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Form, FormGroup, Row } from "reactstrap";
import hidePasswordIcon from "../../assets/hide.svg";
import GroupBossLogo from "../../assets/logo.png";
import GroupBossRoot from "../../assets/root.png";
import showPasswordIcon from "../../assets/view.svg";
import MyLoader from "../Common/MyLoader";

function Login(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const prevAuthRef = useRef();

  const [loginData, setLoginData] = useState({
    emailAddress: "",
    password: "",
    isEmailPresent: false,
    hidden: "password",
    loading: false,
    saveErrors: {},
  });

  useEffect(() => {
    prevAuthRef.current = props.auth;
  }, [props.auth]);

  const prevAuth = prevAuthRef.current;

  useEffect(() => {
    let token = localStorage.getItem("access_token");
    if (token !== null) {
      navigate("/user/dashboard");
    }
    if (location.search) {
      const queryParsed = queryString.parse(location.search);
      if (!(queryParsed.email && queryParsed.email.length > 0)) {
        navigate("/login");
      } else {
        setLoginData((prevState) => ({
          ...prevState,
          emailAddress: queryParsed.email,
          isEmailPresent: true,
        }));
      }
    }
  }, [location.search]);

  useEffect(() => {
    const { auth, loginRedirectReset } = props;
    let saveErrors = {};

    if (auth?.errorMessage && auth?.errorMessage !== prevAuth?.auth?.errorMessage) {
      if (
        auth?.errorMessage ===
        "no active account found with the given credentials"
      ) {
        saveErrors["emailAddress"] =
          "There is no account with this email. Please Sign Up first";
      } else if (auth?.errorMessage === "provided password does not match") {
        saveErrors["password"] = "Password is incorrect";
      }
      setLoginData((prevState) => ({
        ...prevState,
        saveErrors,
      }));
    }

    if (auth.redirect) {
      if (
        auth?.auth &&
        auth?.auth !== prevAuth?.auth?.auth &&
        auth?.auth?.auth
      ) {
        localStorage.setItem("access_token", auth.auth.auth.access_token);
        localStorage.setItem("refresh_token", auth.auth.auth.refresh_token);
        localStorage.setItem("show_board", auth.auth.show_board);
        let emails = [];
        if (auth.auth.user) {
          localStorage.setItem("user_id", auth.auth.user.id);
          auth.auth.user.emails.map((email) => {
            if (email.default) {
              localStorage.setItem("user_default_email", email.email);
            }
            emails.push(email);
          });
          localStorage.setItem("user_emails", JSON.stringify(emails));
          localStorage.setItem("user_first_name", auth.auth.user.first_name);
          localStorage.setItem("user_last_name", auth.auth.user.last_name);
        }
        if (auth?.auth?.show_board) {
          navigate("/user/getting-started");
        } else {
          navigate("/user/dashboard");
        }

        loginRedirectReset();
      }
    }
  }, [props.auth]);

  const onChangeHandle = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({
      ...prevState,
      saveErrors: {},
      [name]: value,
    }));
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const { login } = props;
    if (checkError()) {
      const payload = {
        email: loginData.emailAddress,
        password: loginData.password,
      };
      login(payload);
    }
  };

  const toggleShow = () => {
    setLoginData((prevState) => ({
      ...prevState,
      hidden: !prevState.hidden,
    }));
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;

    function validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    if (loginData.emailAddress === "") {
      formIsValid = false;
      saveErrors["emailAddress"] = "Email address can't be empty";
    }
    if (!validateEmail(loginData.emailAddress)) {
      formIsValid = false;
      saveErrors["emailAddress"] = "Please Insert a valid email address";
    }
    if (loginData.password === "") {
      formIsValid = false;
      saveErrors["password"] = "Password can't be empty";
    }

    setLoginData((prevState) => ({
      ...prevState,
      saveErrors,
    }));

    return formIsValid;
  };

  const { auth } = props;

  return (
    <div className="login-page">
      <MyLoader open={auth.isLoading} />
      <Row style={{ alignItems: "center" }}>
        <Col
          md={4}
          sm={12}
          className="leftbar">
          <Row
            style={{
              flexDirection: "column",
              justifyContent: "space-around",
              marginLeft: "0",
            }}
            className="leftbarpadding">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="leftbar-logo-text">
              <img
                src={GroupBossLogo}
                className="homeImgback2"
                alt="GroupBossLogo Logo"
              />
              <br />
              <span className="sloganLogin">
                Raining Sales From Your Facebook Group{" "}
                <p className="sloganLogin2">
                  <strong>Like a Boss!</strong>
                </p>{" "}
              </span>
            </div>
            <div className="spacer"></div>
            <div>
              <img
                className="homeImgback3"
                src={GroupBossRoot}
                alt="GroupBossLogo Logo"
              />
            </div>
          </Row>
        </Col>

        <Col
          md={8}
          sm={12}>
          {loginData.isEmailPresent && loginData.emailAddress !== "" && (
            <Row
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Col
                xl={5}
                lg={6}
                md={7}
                sm={9}>
                <p
                  style={{
                    fontFamily: "nova,sans-serif",
                    marginBottom: "3rem",
                  }}>
                  We have sent you the password in{" "}
                  <strong>"{loginData.emailAddress}"</strong>. You can change it
                  anytime after the first login.
                </p>
              </Col>
            </Row>
          )}
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Col
              xl={5}
              lg={6}
              md={7}
              sm={9}>
              <div className="loginForm">
                <h3 className="signin">Sign In</h3>
                <br></br>
                <Form
                  className="form"
                  onSubmit={(e) => onSubmitHandler(e)}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="emailAddress"
                      onChange={(event) => onChangeHandle(event)}
                      placeholder="Enter email"
                      value={loginData.emailAddress}
                      disabled={loginData.isEmailPresent ? true : false}
                    />
                    <span className="error-message">
                      {loginData.saveErrors["emailAddress"]}
                    </span>
                  </div>

                  <div className="form-group">
                    <div id="input_containerp">
                      <input
                        type={loginData.hidden ? "password" : "text"}
                        name="password"
                        onChange={(event) => onChangeHandle(event)}
                        id="inputP"
                        className="form-control showPass"
                        placeholder="Enter password"
                        value={loginData.password}
                      />
                      <img
                        style={{ height: "27px", width: "27px" }}
                        src={
                          loginData.hidden ? showPasswordIcon : hidePasswordIcon
                        }
                        alt="show password"
                        id="input_img"
                        title="Show Password"
                        onClick={toggleShow}
                      />
                    </div>
                    <span className="error-message">
                      {loginData.saveErrors["password"]}
                    </span>
                  </div>

                  <div className="form-group">
                    <div
                      style={{ textAlign: "center" }}
                      className="custom-control">
                      <label>
                        <Link
                          to="/forgot-password"
                          style={{ color: "#52565E", fontWeight: "500" }}>
                          Forgot Password?
                        </Link>
                      </label>
                    </div>
                  </div>
                  <FormGroup>
                    <button
                      type="submit"
                      className="btn btn-primary btn-block groupboss">
                      Sign In
                    </button>
                  </FormGroup>

                  <div className="signupInvitation">
                    <br></br>
                    <span>Don't have an account? </span>
                    <p>
                      {" "}
                      <a
                        href="https://groupboss.io/pricing"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#3CCBCF", fontWeight: "700" }}>
                        Sign Up
                      </a>
                    </p>
                  </div>
                  <Col
                    md={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                    className="text-center">
                    <a
                      href="https://groupboss.io/terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#212529",
                        fontFamily: "nova, 'sans-serif'",
                      }}>
                      Terms of Services
                    </a>
                    <div
                      style={{
                        height: "6px",
                        width: "6px",
                        borderRadius: "50%",
                        background: "#33475b",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    />
                    <a
                      href="https://groupboss.io/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#212529",
                        fontFamily: "nova, 'sans-serif'",
                      }}>
                      Privacy Policy
                    </a>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
