//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST_SUCCESS,
  CHANGE_PASSWORD_REQUEST_FAILURE
} from "../../utils/constants";

//Handle reset password request
export function* resetRequest({ payload }) {
  try {
    // const response = yield call(login('url'));
    let url = "auth/reset-password/";
    let details = {
      key: payload.key,
      password: payload.password
    };
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: RESET_PASSWORD_SUCCESS,
      payload: { data: response }
    });
  } catch (err) {
    yield put({
      type: RESET_PASSWORD_FAILURE,
      payload: err
    });
  }
}

//Handle password change request
export function* changePasswordRequest({ payload }) {
  try {
    // const response = yield call(login('url'));
    let url = "auth/users/change-password/";
    let details = {
      old_password: payload.old_password,
      new_password: payload.new_password
    };
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: CHANGE_PASSWORD_REQUEST_SUCCESS,
      payload: { data: response }
    });
  } catch (err) {
    yield put({
      type: CHANGE_PASSWORD_REQUEST_FAILURE,
      payload: err
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(RESET_PASSWORD, resetRequest),
    takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordRequest)
  ]);
}
