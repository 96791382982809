import React from "react";
import { StyledButton } from "../../styledComponents/buttons";
import planCancelled from "../../assets/img/subscription-status-cancelled.svg";
import { reformatDate } from "../../utils/helpers";

const NoPlan = ({memberSince, setModal}) => {
  return (
    <div className="subscontent">
      <div
        style={{ padding: "2.5rem 2.5rem 0rem 2.5rem" }}
        className="innerTop">
        <p className="subheader">Current Plan</p>
        <div className="subs-rate-parent">
          <div className="paused-subs-rate-container">
            <h3>No Plan Selected</h3>
          </div>
          <StyledButton
          onClick={() => {
            setModal(
              {
                upgradeModal: true,
              }
            );
          }}
            backgroundColor="#33CDD1"
            textColor="#fff"
            fontSize="16px"
            fontWeight="600"
            width="180px"
            hoverBackgroundColor="#3ab7bb">
            Upgrade Plan
          </StyledButton>
        </div>
        <div className="subcenter">
          <div className="paused-plan-content">
            <div
              style={{
                margin: "20px 0",
                fontSize: "16px",
              }}>
              <p
                style={{
                  color: "#52575f",
                }}>
                Status
              </p>
              <span>
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "10px",
                  }}
                  src={planCancelled}
                  alt="plan cancelled"
                  width="100%"
                />
                Cancelled
              </span>
            </div>
          </div>
        </div>
        <div>
              <p style={{ color: "#52575f", margin: "20px 0" }}>
                Member Since <span style={{ color: "#000" }}>{reformatDate(memberSince)}</span>{" "}
              </p>
            </div>
      </div>
    </div>
  );
};

export default NoPlan;
