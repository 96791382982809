import {
  LOGIN_REQUEST,
  LOGIN_REDIRECT_RESET,
  LOGIN_PROFILE_CREATED_RESET,
  MODIFY_AUTH_DATA
} from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------
// actions will call form index.js file
export function loginRequest(value) {
  return {
    type: LOGIN_REQUEST,
    payload: value
  };
}

export function loginRedirectReset() {
  return {
    type: LOGIN_REDIRECT_RESET
  };
}

export function loginProfileCreatedReset() {
  return {
    type: LOGIN_PROFILE_CREATED_RESET
  };
}

export function modifyAuthData(value) {
  return {
    type: MODIFY_AUTH_DATA,
    payload: value
  };
}

export const actions = {
  loginRequest,
  loginRedirectReset,
  loginProfileCreatedReset,
  modifyAuthData
};
