import { connect } from "react-redux";

import MyPlan from "./MyPlan";
import {
  getSubscriptionPlan,
  pauseSubscription,
  upgradePlan,
} from "./subscriptionActions";
const mapStateToProps = (state) => ({
  auth: state.auth,
  myPlan: state.subscription.myPlan,
  isLoading: state.subscription.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getSubscriptionPlan: (data) => dispatch(getSubscriptionPlan(data)),
  upgradePlan: (data) => dispatch(upgradePlan(data)),
  cancelPlan: () => dispatch({ type: "CANCEL_PLAN" }),
  manageSubscription: (data) => dispatch(pauseSubscription(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPlan);
