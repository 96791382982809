import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";

export const PageTitle = styled.p`
  font-size: ${({ fontSize }) => fontSize || "12px"};
  color: ${({ color }) => color || "black"}
  font-weight: ${({ fontWeight }) => fontWeight || "500"};
  line-height: ${({ lineHeight }) => lineHeight || "1.2"};
  margin-bottom: ${({ marginBottom }) => marginBottom || "0"};
`;

export const ColoredText = styled.p`
  margin: ${({ margin }) => margin || "0"};
  color: ${({ color, primary, type }) =>
    color
      ? color
      : primary
      ? "black"
      : type && type === "error"
      ? "white"
      : type && type === "blue"
      ? "blue"
      : type && type === "warning"
      ? "orange"
      : "yellow"};
  font-size: ${({ fontSize }) => fontSize || "12px"};
  font-weight: ${({ fontWeight, medium }) =>
    fontWeight ? fontWeight : medium ? "400" : "500"};

  ${({ hover, primary }) =>
    hover &&
    primary &&
    css`
      &:hover {
        color: "white";
      }
    `}
`;

export const CommonGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `auto auto`};
  justify-content: ${({ justifyContent }) => justifyContent || `space-between`};
  align-content: ${({ alignContent }) => alignContent};
  align-items: ${({ alignItem }) => alignItem};
  grid-gap: ${({ gap }) => gap || `15px`};
  padding: ${({ padding }) => padding || "0"};
  margin: ${({ margin }) => margin};

  ${({ newTask }) =>
    newTask &&
    css`
      grid-template-columns: 100%;
      border: 1px solid blue;
    `}
  ${({ noTask, newTask }) =>
    noTask &&
    newTask &&
    css`
      margin: 20px 25px 0px;
      border-radius: "50px";
    `}
`;

export const CommonText = styled.p`
  margin: ${({ margin }) => margin || `auto 0`};
  color: ${({ color, name, label }) =>
    color ? color : name ? "black" : label ? "white" : "orange"};
  font-size: ${({ fontSize, label }) =>
    fontSize ? fontSize : label ? "24px" : "16px"};
  font-weight: ${({ fontWeight, title, label }) =>
    fontWeight ? fontWeight : label ? "400" : title ? "500" : "600"};
  text-align: ${({ textAlign }) => textAlign && textAlign};
`;

export const Container = styled.div`
  display: grid;
  background-color: ${({ bgColor }) => bgColor || "white"};
  border-radius: ${({ borderRadius }) => borderRadius || "50px"};
  padding: ${({ padding }) => padding || "10px"};
  box-shadow: 0px 20px 40px #94a6ce1a;
  @media (max-width: 1250px) {
    max-width: 100vw;
    overflow: auto;
  }
`;

export const Content = styled.div`
  padding: ${({ padding }) => padding || `30px`};
  min-height: 100vh;
  background-color: #f6f9fb;
`;

export const InputWithLabelSection = styled.div`
  display: grid;
  grid-row-gap: ${({ gap }) => gap || "6px"};
`;

export const PackageWrapper = styled.div`
  background-color: #fff;
  border-radius: 15px;

  padding: 2rem 1rem;
  flex: 1;
  text-align: center;
  box-shadow: 1px 1px #f0ecec;
  border: 2px solid white;

  &:hover {
    border-color: ${(props) => `${!props.blur && props.borderColor}`};
  }
  position: relative;
  pointer-events: ${(props) => (props.blur ? "none" : "auto")};
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  opacity: ${(props) => (props?.blur ? "0.2" : "1")};
`;

export const CapitalizedH3 = styled.h3`
  &::first-letter {
    text-transform: uppercase;
  }
`;
