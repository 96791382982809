import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Row } from "reactstrap";
import CustomNavbar from "../CustomNavbar";

function Admin(props) {
  const location = useLocation();
  const [userFullName, setUserFullName] = useState(
    localStorage.getItem("user_default_email")
  );

  useEffect(() => {
    setUserFullName(localStorage.getItem("user_default_email"));
  }, []);

  useEffect(() => {
    if (location.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      if (mainPanelRef.current) {
        mainPanelRef.current.scrollTop = 0;
      }
    }
  }, [location.action]);

  const mainPanelRef = React.createRef();

  return (
    <div ref={mainPanelRef}>
      {location.pathname === "/user/getting-started" ||
      location.pathname === "/user/getting-started-video" ? (
        ""
      ) : (
        <Row>
          <CustomNavbar
            {...props}
            color1={"color1"}
            color2={"color2"}
            userFullName={userFullName}
          />
        </Row>
      )}
      <Outlet />
    </div>
  );
}

export default Admin;
