export const BASE_URL = "https://groupboss.io/api";

export const getToken = () => {
  const token = localStorage.getItem("access_token");
  return token;
};

export const setToken = (token) => {
  localStorage.setItem("access_token", token);
};

export const getValues = () => {
  const token = localStorage.getItem("values");
  return token;
};

export const setValues = (values) => {
  localStorage.setItem("values", values);
};

export const getdataVisible = () => {
  const token = localStorage.getItem("dataVisible");
  return token;
};

export const setdataVisible = (dataVisible) => {
  localStorage.setItem("dataVisible", dataVisible);
};

export const password_validate = (p) => {
  return /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/.test(p);
};

export const validateEmail = (email) => {
  // eslint-disable-next-line
  let emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const reformatDate = (date) => {
  if (date === null || date === undefined || date === "") {
    return "";
  }
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const d = new Date(date);

  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};

const plans = JSON.parse(process.env.REACT_APP_PADDLE_PLANS);

function getPlanValue(planId, planType) {
  for (const key in planType) {
    if (planType[key].includes(planId)) {
      return key;
    }
  }
  return ""; // Return null if planId is not found in any of the arrays
}
const plan_type = JSON.parse(process.env.REACT_APP_PLAN_TYPES);

export const getPlanType = (plan_id) => {
  plan_id = parseInt(plan_id);
  return getPlanValue(plan_id, plan_type);
};

export const getPlanName = (planId) => {
  planId = parseInt(planId);
  if(plans[planId]) {
    return plans[planId]
  }
  return "Unknown";
};

let currencies = {
  "USD": "$",
  "EUR": "€",
  "GBP": "£",
  "ARS": "$",
  "BRL": "R$",
  "AUD": "$",
  "CAD": "$",
  "CHF": "Fr",
  "COP": "$",
  "CNY": "¥",
  "CZK": "Kč",
  "DKK": "kr",
  "HKD": "$",
  "HUF": "Ft",
  "INR": "₹",
  "ILS": "₪",
  "JPY": "¥",
  "KRW": "₩",
  "MXN": "$",
  "NOK": "kr",
  "NZD": "$",
  "PLN": "zł",
  "RUB": "₽",
  "SEK": "kr",
  "SGD": "$",
  "THB": "฿",
  "TRY": "₺",
  "TWD": "NT$",
  "UAH": "₴",
  "ZAR": "R"
}


export const getCurrencySymbol = (currency) => {
  if (currencies[currency]) {
    return currencies[currency]
  }
  return currency;
}
