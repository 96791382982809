import React, { useEffect, useState } from "react";
import { createNotification } from "../../utils/notificationManager";
import MyLoader from "../Common/MyLoader";
import CancelModal from "./CancelModal";
import CurrentPlan from "./CurrentPlan";
import NoPlan from "./NoPlan";
import { initializePaddle } from "./Paddle";
import PausedPlan from "./PausedPlan";
import UpgradePlanModal from "./UpgradePlanModal";
import { getSubscriptionPlan } from "./subscriptionActions";
import { useLocation } from "react-router-dom";

const MyPlan = (props) => {
  const [Modal, setModal] = useState({
    cancelModal: false,
    activateModal: false,
    upgradeModal: false,
  });

  const [loader, setLoader] = useState(false);
  let { state } = useLocation();
  const initiateAfterCheckoutLoader = (eventInfo) => {
    if (eventInfo?.override) {
      const splittedURL = eventInfo?.override?.split("/");
      // Checking if update override url ends with cancel or not, if yes then update store state
      if (splittedURL[splittedURL.length - 1] === "cancel") {
        props.cancelPlan();
      }
    }

    // Set Loader true for 5 seconds
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      props.getSubscriptionPlan(true); // Call getSubscriptionPlan after 5 seconds
    }, 5000);
  };


  

  useEffect(() => {
    initializePaddle(initiateAfterCheckoutLoader);
    if(state?.subs_modal){
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
        setModal({
          ...Modal,
          upgradeModal: true
        })
      }, 5000);
      
    }
  }, []);

  useEffect(() => {
    const { getSubscriptionPlan } = props;
    getSubscriptionPlan();
  }, [getSubscriptionPlan]);

  const initiatePlanUpgrade = (data) => {
    if (parseInt(data?.plan_id) === parseInt(props.myPlan?.paddle_plan_id)) {
      createNotification("warning", "You already have this plan", 2000);
      return;
    }
    props.upgradePlan(data);
  };

  const { myPlan, isLoading, auth, manageSubscription } = props;

  const initiateSubscriptionManager = (data) => {
    manageSubscription(data);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  }
  return (
    <div
      style={{
        padding: "20px 5%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <MyLoader open={isLoading || loader} />
      <CancelModal
        cancelURL={myPlan?.cancel_url}
        modal={Modal}
        setModal={setModal}
        managePauseSubscription={manageSubscription}
        isPaused={myPlan?.payment_information?.paused_at ? true : false}
      />
      <UpgradePlanModal
        modal={Modal}
        setModal={setModal}
        planStatus={myPlan?.status}
        planId={myPlan?.paddle_plan_id}
        userMail={
          auth?.auth?.user?.emails?.find((item) => item?.default === true)
            ?.email
        }
        initiatePlanUpgrade={initiatePlanUpgrade}
      />
      <div
        style={{
          marginBottom: "20px",
        }}
        className="dashboardHeader"
      >
        <div>
          <span className="homeHead">My Subscription Plan</span>
        </div>
      </div>
      <div className="subscontent">
        {(myPlan?.status === "active" ||
          myPlan?.status === "trialing" ||
          myPlan?.status === "past_due") &&
        myPlan.is_active === true &&
        myPlan.status !== "deleted" ? (
          <CurrentPlan
            planInfo={myPlan}
            modal={Modal}
            setModal={setModal}
            resumeSubscription={initiateSubscriptionManager}
          />
        ) : myPlan?.status === "paused" ? (
          <PausedPlan
            planInfo={myPlan}
            manageSubscription={initiateSubscriptionManager}
          />
        ) : (
          <NoPlan memberSince={myPlan?.created_at} setModal={setModal} />
        )}
      </div>
    </div>
  );
};

export default MyPlan;
