import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  FORGOT_PASSWORD_REQUEST_FAILURE
} from "../../utils/constants";
import { createNotification } from "../../utils/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleForgotPasswordRequest = (state, action) => {
  return {
    ...state,
    isLoading: true
  };
};

export const handleForgotPasswordRequestSuccess = (state, action) => {
  const {
    payload: { data }
  } = action;
  if (data.status === 200) {
    createNotification(
      "success",
      "Reset password link has been sent to your email",
      3000
    );
    return {
      ...state,
      isLoading: false,
      redirect: true
    };
  }
  return {
    ...state,
    isLoading: false
  };
};

export const handleForgotPasswordRequestFailure = (state, action) => {
  if (action.payload.response === undefined) {
    createNotification("error", "No internet connection found", 3000);
    return {
      ...state,
      isLoading: false
    };
  }
  const {
    payload: {
      response: { data, status }
    }
  } = action;
  if (status === 400) {
    if (data.email) {
      createNotification("error", data.email[0], 4000);
    } else {
      createNotification("error", "An error occured. Please Try Again", 4000);
    }
  }
  return {
    ...state,
    isLoading: false
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [FORGOT_PASSWORD_REQUEST]: handleForgotPasswordRequest,
  [FORGOT_PASSWORD_REQUEST_SUCCESS]: handleForgotPasswordRequestSuccess,
  [FORGOT_PASSWORD_REQUEST_FAILURE]: handleForgotPasswordRequestFailure
};

// default initial state
const initialState = {
  redirect: false,
  isLoading: false
};

export default function forgotPasswordReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
