import { connect } from "react-redux";
import { changePassword } from "./AccountSettingsActions";

// Component
import AccountSettings from "./AccountSettings";

import { editRequest } from "./AccountSettingsActions";

// Store props to map with current state
const mapStateToProps = state => ({
  passwordChanged: state.resetPassword.passwordChanged,
  isLoading: state.resetPassword.isLoading
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  editRequest: details => dispatch(editRequest({ ...details })),
  changePassword: details => dispatch(changePassword({ ...details }))
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
