import { connect } from "react-redux";

// Component
import CustomNavbar from "./CustomNavbar";

// Actions
import { loginProfileCreatedReset } from "../Login/loginActions";
import { getGroupList } from "../Dashboard/dashboardActions";
import { selectGroup } from "../Table/tableActions";

// Store props to map with current state
const mapStateToProps = state => ({
  auth: state.auth,
  groupList: state.dashboard.groupList,
  selectedGroup: state.table.selectedGroup
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getGroupList: () => dispatch(getGroupList()),
  selectGroup: details => dispatch(selectGroup({ ...details })),
  loginProfileCreatedReset: () => dispatch(loginProfileCreatedReset())
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CustomNavbar);
