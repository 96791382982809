import React, { useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from "reactstrap";
import GroupBossLogo from "../../assets/logo.png";
import DropdownStyle from "../../utils/DropdownStyle";

function NoOptionsMessage(props) {
  if (props.options.length)
    return <span className="customNoOption">No group found</span>;
}

function CustomNavbar(props) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({
    id: "",
    name: "",
    windowWidth: 0,
  });
  const navigate = useNavigate();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const selectGroup = (e) => {
    let selectedGroupData = {
      id: e.value,
      name: e.label,
    };
    localStorage.setItem("selected_group", JSON.stringify(selectedGroupData));
    props.selectGroup(e);
    navigate("/user/data-table");
  };

  const handleLogOut = (e) => {
    const { loginProfileCreatedReset } = props;
    e.preventDefault();

    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_first_name");
    localStorage.removeItem("user_last_name");
    localStorage.removeItem("user_emails");
    localStorage.removeItem("user_default_email");
    localStorage.removeItem("selected_group");
    navigate("/login");
    loginProfileCreatedReset();
  };

  const updateSize = () => {
    setSelectedGroup((prevState) => ({
      ...prevState,
      windowWidth: window?.innerWidth,
    }));
  };

  useEffect(() => {
    props.getGroupList();
    updateSize();
  }, []);

  useEffect(() => {
    if (props.selectedGroup !== selectedGroup) {
      setSelectedGroup(props.selectedGroup);
    }
  }, [props.selectedGroup]);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  let groupListOptions = [];
  if (props.groupList && props.groupList.length > 0) {
    groupListOptions = props.groupList.map((group) => ({
      label: group.name,
      value: group.group_id,
    }));
  }

  return (
    <div className="apptop">
      <Navbar color="light" light expand="md">
        <NavbarBrand href="/">
          {" "}
          <img
            src={GroupBossLogo}
            style={{ height: "40px", marginLeft: "50px" }}
            alt="GroupBossLogo"
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem
              style={{
                paddingTop: selectedGroup?.windowWidth < 1056 ? "0px" : "2px",
                margin: "0px",
              }}
              className="dashboarNavbar"
            >
              <Link
                to="/user/dashboard"
                style={{ color: "#33CDD1" }}
                className="linto"
              >
                Lead Overview
              </Link>
            </NavItem>

            <NavItem
              style={{
                position: "relative",
                width: "210px",
              }}
            >
              <Select
                placeholder="Search your group..."
                options={groupListOptions}
                onChange={(e) => selectGroup(e)}
                isSearchable
                value={
                  location.pathname === "/user/dashboard"
                    ? null
                    : selectedGroup && selectedGroup.id !== ""
                    ? {
                        label: selectedGroup.name,
                        value: selectedGroup.id,
                      }
                    : JSON.parse(localStorage.getItem("selected_group"))
                    ? {
                        label: JSON.parse(
                          localStorage.getItem("selected_group")
                        ).name,
                        value: JSON.parse(
                          localStorage.getItem("selected_group")
                        ).id,
                      }
                    : null
                }
                styles={DropdownStyle(selectedGroup?.windowWidth)}
                components={{ NoOptionsMessage }}
              />
            </NavItem>

            <UncontrolledDropdown inNavbar>
              <DropdownToggle nav style={{ padding: "2px" }}>
                <div className="helpDiv">
                  <div className="questionDiv">
                    <p className="question">?</p>
                  </div>
                  <div>
                    <span className="helpText">Help</span>
                  </div>
                </div>
              </DropdownToggle>
              <DropdownMenu left>
                <DropdownItem
                  href="https://support.groupboss.io/"
                  target="_blank"
                >
                  Docs / FAQ
                </DropdownItem>
                <DropdownItem
                  href="https://www.youtube.com/channel/UCkrO5Eod4EiIzC3P_fZScwg?sub_confirmation=1"
                  target="_blank"
                >
                  Videos
                </DropdownItem>
                <DropdownItem
                  href="https://web.facebook.com/groups/groupboss.io/?_rdc=1&_rdr"
                  target="_blank"
                >
                  Join Our Community
                </DropdownItem>
                <DropdownItem
                  href="https://groupboss.io/partner"
                  target="_blank"
                >
                  Join Affiliate Program
                </DropdownItem>
                <DropdownItem
                  href="https://groupboss.io/group-manage-program"
                  target="_blank"
                >
                  Groupmanage Program
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <FaUserAlt /> &nbsp; {props.userFullName}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-right" end>
                <DropdownItem
                  onClick={() => {
                    navigate("/user/change-password");
                  }}
                >
                  Change Password
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate("/user/subscription");
                  }}
                >
                  My Plan
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    window.open("https://groupboss.io/affiliate/", "_blank");
                  }}
                >
                  Refer and Earn
                </DropdownItem>

                <DropdownItem onClick={(e) => handleLogOut(e)}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default CustomNavbar;
