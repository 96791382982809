import React from "react";

import { useNavigate } from "react-router-dom";
import arrowLeftHover from "../../assets/arrow-left-hover.svg";
import arrowLeft from "../../assets/arrow-left.svg";
import imageGuide from "../../assets/image-guide.svg";
import playIcon from "../../assets/play.svg";
import { useDashboardRedirector } from "../../hooks/custom/useDashboardRedirector";

const GettingStartedVideo = (props) => {
  const navigate = useNavigate();

  // to prevent access this page without proper condition check or manual url change
  useDashboardRedirector();
  return (
    <div className="introText">
      <div>
        <p className="gettingText">Getting Started With Groupboss</p>
        <p className="gettingTextNormal">
          Here is a 2 step onboarding guide for getting started with Groupboss
        </p>
        <div className="gettingVideoSecond">
          <div className="imageDiv">
            <img
              src={imageGuide}
              alt="extension"
              className="imageExtension"
              style={{ height: "420px" }}
            />
            <a
              href="https://www.youtube.com/watch?v=_H1exZP5vhE"
              target="_blank">
              <div className="playBackground">
                <img
                  src={playIcon}
                  alt="play"
                />
              </div>
            </a>
          </div>
          <div className="downloadExtension">
            <p
              className="gettingTextNormal"
              style={{ opacity: 1, marginBottom: "30px" }}>
              Before you jump with Groupboss, Let’s watch a quick video first.
            </p>
            <div
              className="getStartedButton"
              onClick={() => navigate("/user/dashboard")}>
              <span className="getStartedText">Get Started</span>
            </div>
          </div>
          <div>
            <div
              className="arrowBox"
              onClick={() => navigate("/user/getting-started")}>
              <img
                src={arrowLeftHover}
                alt="arrow"
                className="arrowRightClass"
              />
              <img
                src={arrowLeft}
                alt="arrow"
                className="arrowRightClassHover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStartedVideo;
