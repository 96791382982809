import styled from "styled-components";

export const PrimaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${({ height }) => height || "60px"};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding || "0 25px"};
  cursor: ${({ cursor }) => cursor || `pointer`};

  background-color: ${({ bgColor }) => bgColor || "white"};
  color: ${({ textColor }) => textColor || "black"};
  font-size: ${({ fontSize }) => fontSize || "16px"};
  font-weight: ${({ fontWeight }) => fontWeight || "300"};
  border-radius: "6px";
  border: none;
  white-space: ${({ nowrap }) => nowrap && `nowrap`};
  &:hover {
    background-color: ${({ hoverColor, disabled }) =>
      !disabled && (hoverColor || "5C59F0")};
  }
  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${({ disabledBgColor }) => disabledBgColor || "#dce2ef"};
    cursor: not-allowed;
  }
`;

// div styled buttons
const CommonDivButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding || "0 25px"};
  cursor: ${({ cursor }) => cursor || `pointer`};
  border-radius: ${({ borderRadius }) => borderRadius || "50px"};
  margin: ${({ margin }) => margin};
`;
export const SecondaryButton = styled(CommonDivButton)`
  min-height: ${({ height }) => height || "40px"};

  background-color: ${({ bgColor }) => bgColor || "#fff"};
  color: ${({ textColor }) => textColor || "#132742"};
  font-size: ${({ fontSize }) => fontSize || "13px"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  border: 1px solid ${({ borderColor }) => borderColor || "#635BFF"};

  &:hover {
    background-color: ${({ hoverBgColor }) => hoverBgColor || "#635BFF"};
    border-color: ${({ hoverBgColor }) => hoverBgColor || "#635BFF"};
    color: ${({ hoverTextColor }) => hoverTextColor || "#fff"};
  }
`;
export const WhiteButton = styled(CommonDivButton)`
  min-height: ${({ height }) => height || "40px"};

  background-color: ${({ bgColor }) => bgColor || "#fff"};
  color: ${({ color, type }) =>
    color
      ? color
      : type && type === "cancel"
      ? "#3d4d69"
      : type && type === "delete"
      ? "#FE5969"
      : "#3d4d69"};
  font-size: ${({ fontSize }) => fontSize || "13px"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  border: 1px solid
    ${({ borderColor, type }) =>
      borderColor
        ? borderColor
        : type && type === "cancel"
        ? "#e4e7eb"
        : type && type === "delete"
        ? "#e4e7eb"
        : "#e4e7eb"};

  &:hover {
    border-color: ${({ hoverBorderColor, type }) =>
      hoverBorderColor
        ? hoverBorderColor
        : type && type === "cancel"
        ? "#c2cce1"
        : type && type === "delete"
        ? "#FE5969"
        : "#c2cce1"};
  }
`;
export const ColoredButton = styled(CommonDivButton)`
  min-height: ${({ height, theme }) => height || theme.button.height};
  background-color: ${({ bgColor, type, theme }) =>
    bgColor
      ? bgColor
      : type && type === "delete"
      ? theme.button.deleteFilled.bgColor
      : type && type === "blue"
      ? theme.button.blueButton.bgColor
      : type && type === "gray"
      ? theme.button.grayButton.bgColor
      : type && type === "grayLight"
      ? theme.button.grayLightButton.bgColor
      : type && type === "black"
      ? theme.button.blackButton.bgColor
      : theme.button.primary.bgColor};
  color: ${({ color, theme }) => color || theme.colors.main.white};
  font-size: ${({ fontSize, theme }) => fontSize || theme.button.fontSize};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight || theme.fontWeights.medium};
  border: none;

  &:hover {
    background-color: ${({ hoverBgColor, bgColor, type, theme }) =>
      hoverBgColor
        ? hoverBgColor
        : bgColor
        ? bgColor
        : type && type === "delete"
        ? theme.button.deleteFilled.hoverBgColor
        : type && type === "blue"
        ? theme.button.blueButton.hoverBgColor
        : type && type === "gray"
        ? theme.button.grayButton.hoverBgColor
        : type && type === "grayLight"
        ? theme.button.grayLightButton.hoverBgColor
        : type && type === "black"
        ? theme.button.blackButton.hoverBgColor
        : theme.button.primary.hoverBgColor};
  }
`;

export const ButtonIcon = styled.img`
  width: ${({ size }) => size || "18px"};
  height: ${({ size }) => size || "18px"};
  margin-top: -2px;
`;
export const WhiteButtonWithIcon = styled(WhiteButton)`
  grid-column-gap: 8px;
  margin: ${({ margin }) => margin};
`;
export const ColoredButtonWithIcon = styled(ColoredButton)`
  grid-column-gap: 8px;
  margin: ${({ margin }) => margin};
`;

export const ModalDeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${({ height, theme }) => height || theme.button.height};
  padding: ${({ padding }) => padding || "0 25px"};

  color: ${({ theme }) => theme.button.deleteFilled.textColor};
  font-size: ${({ theme }) => theme.button.fontSize};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  background-color: ${({ theme }) => theme.button.deleteFilled.bgColor};
  border: none;

  &:hover {
    background-color: ${({ disabled, theme }) =>
      !disabled && theme.button.deleteFilled.hoverBgColor};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) =>
      theme.button.deleteFilled.disabledBgColor};
  }
  &:focus {
    outline: none;
  }
`;

export const TabButtonContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `auto auto`};
  justify-content: start;
  align-items: center;
  grid-column-gap: 5px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 3px;
  background-color: ${({ theme }) => theme.colors.main.white};
  margin: ${({ margin }) => margin};
`;

export const TabButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 13px;
  font-weight: ${({ selected, index, theme }) =>
    index === selected ? theme.fontWeights.medium : theme.fontWeights.regular};
  padding: 0 12px;
  border-radius: ${({ theme }) => theme.button.borderRadius};
  color: ${({ index, selected, theme }) =>
    index === selected ? theme.colors.main.white : theme.colors.main.textMain};
  background-color: ${({ index, selected, theme }) =>
    index == selected ? theme.colors.main.primary : theme.colors.main.white};
  cursor: pointer;
  border: 1px solid
    ${({ index, selected, theme }) =>
      index == selected ? theme.colors.main.primary : "transparent"};

  &:hover {
    border-color: ${({ theme }) => theme.colors.main.primary};
  }
`;

export const StyledButton = styled.button`
  background-color: ${(props) =>
    props.backgroundColor ||
    "#007bff"};
  color: ${(props) =>
    props.textColor ||
    "#fff"};
  font-size: ${(props) =>
    props.fontSize ||
    "14px"};
  font-weight: ${(props) =>
    props.fontWeight ||
    "400"};
  padding: ${(props) =>
    props.padding ||
    "8px 5px"};
  width: ${(props) =>
    props.width ||
    "auto"};
  border: ${props => props.border || "none"};
  border-radius: 7px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a smooth transition for the background color */

&:hover {
  background-color: ${(props) => props.hoverBackgroundColor || ""}; /* Change background color on hover */
  color: ${(props) => props.hoverTextColor || ''}; /* Change text color on hover */
  border: ${props => props.hoverBorder || ""};
}
`;
