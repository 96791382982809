import React from "react";
import cardAmex from "../../assets/img/payment-methods/card-american-express.svg";
import cardDclub from "../../assets/img/payment-methods/card-dinersclub.svg";
import cardDiscover from "../../assets/img/payment-methods/card-discover.svg";
import cardJcb from "../../assets/img/payment-methods/card-jcb.svg";
import cardMaestro from "../../assets/img/payment-methods/card-maestro.svg";
import cardMaster from "../../assets/img/payment-methods/card-master.svg";
import cardPaypal from "../../assets/img/payment-methods/card-paypal.svg";
import cardUnion from "../../assets/img/payment-methods/card-unionpay.svg";
import cardVisa from "../../assets/img/payment-methods/card-visa.svg";
import { openModal } from "./Paddle";

const getCardImage = (cardType) => {
  cardType = cardType.toLowerCase();
  switch (cardType) {
    case "master":
      return cardMaster;
    case "american_express":
      return cardAmex;
    case "diners_club":
      return cardDclub;
    case "discover":
      return cardDiscover;
    case "visa":
      return cardVisa;
    case "union_pay":
      return cardUnion;
    case "paypal":
      return cardPaypal;
    case "jcb":
      return cardJcb;
    case "maestro":
      return cardMaestro;
    default:
      return "";
  }
};

const CardContainer = ({ cardInfo, updateURL }) => {
  // if no card info found for a user, dont show any card related info
  if (!cardInfo?.payment_method) {
    return;
  }

  return (
    <div>
      {" "}
      <hr style={{}} />
      <div className="bottomsubcontainer">
        <div>
          <p>Payment Method</p>
          <span
            style={{
              color: "#52575f",
              display: "flex",
              gap: "15px",
              alignItems: "start",
            }}>
            <img
              style={{
                height: "25px",
                width: "45px",
              }}
              src={getCardImage(
                cardInfo?.card_type || cardInfo?.payment_method
              )}
              alt=""
            />

            <p>
              {" "}
              {cardInfo?.last_four_digits
                ? `**** ${cardInfo?.last_four_digits}`
                : cardInfo?.payment_method}
            </p>
          </span>
        </div>

        <div
          style={{
            display: cardInfo?.payment_method !== "PayPal" ? "block" : "none",
          }}>
          <p>Expiry Date</p>
          <span style={{ color: "#52575f" }}>{cardInfo?.expiry_date}</span>
        </div>
        <p
          onClick={() => {
            openModal(updateURL);
          }}
          className="change-card"
          style={{
            color: "#007bff",
            cursor: "pointer",
          }}>
          Change Card
        </p>
      </div>
    </div>
  );
};

export default CardContainer;
