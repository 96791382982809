import {
  SELECT_GROUP,
  REQUEST_TABLE_DATA,
  REQUEST_TABLE_DATA_BY_DATE
} from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function selectGroup(value) {
  return {
    type: SELECT_GROUP,
    payload: value
  };
}

export function getTableData(value) {
  return {
    type: REQUEST_TABLE_DATA,
    payload: value
  };
}

export function getTableDataByDate(value) {
  return {
    type: REQUEST_TABLE_DATA_BY_DATE,
    payload: value
  };
}

export const actions = {
  selectGroup,
  getTableData,
  getTableDataByDate
};
