import {
  LOGIN_PROFILE_CREATED_RESET,
  REQUEST_DASHBOARD_DATA,
  REQUEST_DASHBOARD_DATA_FAILURE,
  REQUEST_DASHBOARD_DATA_SUCCESS,
  REQUEST_GROUPLIST_DATA,
  REQUEST_GROUPLIST_DATA_FAILURE,
  REQUEST_GROUPLIST_DATA_SUCCESS,
  REQUEST_GROUPWISE_DATA,
  REQUEST_GROUPWISE_DATA_FAILURE,
  REQUEST_GROUPWISE_DATA_SUCCESS,
} from "../../utils/constants";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleGroupListDataRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    redirect: false,
  };
};

export const handleGroupListDataRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      groupList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

export const handleGroupListDataRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;

  let token = localStorage.getItem("access_token");
  if (token === null) {
    return {
      ...state,
      isLoading: false,
      redirect: true,
      data: null,
    };
  }
  if (response.status === 401 && response.data.code === "user_inactive") {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      data: null,
      subscriptionStatus: "inactive",
    };
  }
  if (
    token &&
    response?.status === 403 &&
    response?.data?.detail ===
      "only active subscribers can perform this operation"
  ) {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      data: null,
      subscriptionStatus: "paused",
    };
  }

  return {
    ...state,
    isLoading: false,
    redirect: false,
    groupList: null,
  };
};

export const handleDashboardDataRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    redirect: false,
  };
};

export const handleDashboardDataRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      data: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

export const handleDashboardDataRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  let token = localStorage.getItem("access_token");
  if (token === null) {
    return {
      ...state,
      isLoading: false,
      redirect: true,
      data: null,
    };
  }
  if (response.status === 401 && response.data.code === "user_inactive") {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      data: null,
      subscriptionStatus: "inactive",
    };
  }

  if (
    token &&
    response?.status === 403 &&
    response?.data?.detail ===
      "only active subscribers can perform this operation"
  ) {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      data: null,
      subscriptionStatus: "paused",
    };
  }

  return {
    ...state,
    isLoading: false,
    redirect: false,
    data: null,
  };
};

export const handleGroupwiseDataRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    redirect: false,
  };
};

export const handleGroupwiseDataRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      data: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

export const handleGroupwiseDataRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  let token = localStorage.getItem("access_token");
  if (token === null) {
    return {
      ...state,
      isLoading: false,
      redirect: true,
      data: null,
    };
  }
  if (response.status === 401 && response.data.code === "user_inactive") {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      data: null,
      subscriptionStatus: "inactive",
    };
  }
  if (
    token &&
    response?.status === 403 &&
    response?.data?.detail ===
      "only active subscribers can perform this operation"
  ) {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      data: null,
      subscriptionStatus: "paused",
    };
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
    data: null,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [REQUEST_DASHBOARD_DATA]: handleDashboardDataRequest,
  [REQUEST_DASHBOARD_DATA_SUCCESS]: handleDashboardDataRequestSuccess,
  [REQUEST_DASHBOARD_DATA_FAILURE]: handleDashboardDataRequestFailure,
  [REQUEST_GROUPWISE_DATA]: handleGroupwiseDataRequest,
  [REQUEST_GROUPWISE_DATA_SUCCESS]: handleGroupwiseDataRequestSuccess,
  [REQUEST_GROUPWISE_DATA_FAILURE]: handleGroupwiseDataRequestFailure,
  [REQUEST_GROUPLIST_DATA]: handleGroupListDataRequest,
  [REQUEST_GROUPLIST_DATA_SUCCESS]: handleGroupListDataRequestSuccess,
  [REQUEST_GROUPLIST_DATA_FAILURE]: handleGroupListDataRequestFailure,
  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  redirect: false,
  groupList: undefined,
  data: undefined,
};

export default function dashboardReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
