import React from "react";

import { useNavigate } from "react-router-dom";
import arrowRightHover from "../../assets/arrow-right-hover.svg";
import arrowRight from "../../assets/arrow-right.svg";
import chromeStoreBadge from "../../assets/chrome-store-badge.svg";
import imageExtension from "../../assets/image-extension-2.png";
import { useDashboardRedirector } from "../../hooks/custom/useDashboardRedirector";

const GettingStarted = (props) => {
  // to prevent access this page without proper condition check or manual url change
  useDashboardRedirector();
  const navigate = useNavigate();

  return (
    <div className="introText">
      <div>
        <p className="gettingText">Getting Started With Groupboss</p>
        <p className="gettingTextNormal">
          Here is a 2 step onboarding guide for getting started with Groupboss
        </p>
        <div className="gettingVideo">
          <div className="extensionDiv">
            <img
              src={imageExtension}
              alt="extension"
              className="imageExtension"
            />
          </div>
          <div className="downloadExtension">
            <p
              className="gettingTextNormal"
              style={{ opacity: 1, marginBottom: "30px" }}>
              Download the chrome extension and start managing your data
            </p>
            <a
              href="https://chrome.google.com/webstore/detail/groupboss/gakcpcoikgklfbajjkdaomcfkpeiobfl?hl=en-US"
              target="_blank">
              <img
                src={chromeStoreBadge}
                alt="badge"
              />
            </a>
          </div>
          <div>
            <div
              className="arrowBox"
              onClick={() => navigate("/user/getting-started-video")}>
              <img
                src={arrowRightHover}
                alt="arrow"
                className="arrowRightClass"
              />
              <img
                src={arrowRight}
                alt="arrow"
                className="arrowRightClassHover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;
