import { connect } from "react-redux";
import { changePassword } from "./changePasswordActions";

// Component
import ChangePassword from "./ChangePassword";

// Store props to map with current state
const mapStateToProps = state => ({
  passwordChanged: state.resetPassword.passwordChanged,
  isLoading: state.resetPassword.isLoading
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  changePassword: details => dispatch(changePassword({ ...details }))
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
