import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import hidePasswordIcon from "../../assets/hide.svg";
import GroupBossLogo from "../../assets/logo.png";
import GroupBossRoot from "../../assets/root.png";
import showPasswordIcon from "../../assets/view.svg";
import { password_validate } from "../../utils/helpers";
import Loader from "../Common/MyLoader";

function ResetPassword(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
    confirmation_key: "",
    isPasswordVisible: false,
    saveErrors: {},
  });

  useEffect(() => {
    let token = localStorage.getItem("access_token");

    if (token != null) {
      navigate("/user/dashboard");
    }
    if (!location.search) {
      navigate("/login");
    } else {
      const queryParsed = queryString.parse(location.search);
      if (!(queryParsed.key && queryParsed.key.length > 0)) {
        navigate("/login");
      } else {
        setState((prevState) => ({
          ...prevState,
          confirmation_key: queryParsed.key,
        }));
      }
    }
  }, [location.search]);

  useEffect(() => {
    const { resetPassword, resetRedirectReset } = props;
    if (resetPassword.redirect === true) {
      resetRedirectReset();
      navigate("/login");
    }
  }, [props.resetPassword, props.resetRedirectReset]);

  const onTextChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      saveErrors: {},
      [name]: value,
    }));
  };

  const handleReset = (e) => {
    e.preventDefault();
    const { password, confirmation_key } = state;
    const { reset } = props;
    if (handleSaveError()) {
      let payload = {
        key: confirmation_key,
        password,
      };
      reset(payload);
    }
  };

  const handleSaveError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (state.password === "") {
      formIsValid = false;
      saveErrors["password"] = "Password can't be empty";
    }
    if (
      state.password !== "" &&
      state.confirmPassword !== "" &&
      state.password !== state.confirmPassword &&
      password_validate(state.password) === true
    ) {
      formIsValid = false;
      saveErrors["confirmPassword"] = "Passwords do not match";
    }
    if (state.password !== "" && password_validate(state.password) === false) {
      formIsValid = false;
      saveErrors["password"] =
        "Passwords must contain minimum eight characters, at least one uppercase letter, one lowercase letter, and a number";
    }

    setState((prevState) => ({
      ...prevState,
      saveErrors,
    }));
    return formIsValid;
  };

  const toggleShow = () => {
    setState((prevState) => ({
      ...prevState,
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  const { confirmPassword, password, isPasswordVisible } = state;

  return (
    <div className="login-page">
      <Loader open={props.resetPassword.isLoading} />
      <Row className="align-items-center">
        <Col
          md={4}
          sm={12}
          className="leftbar">
          <Row
            style={{
              flexDirection: "column",
              justifyContent: "space-around",
              marginLeft: "0",
            }}
            className="leftbarpadding">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="leftbar-logo-text">
              <img
                src={GroupBossLogo}
                className="homeImgback2"
                alt="GroupBossLogo Logo"
              />
              <br />
              <span className="sloganLogin">
                Raining Sales From Your Facebook Group{" "}
                <p className="sloganLogin2">
                  <strong>Like a Boss!</strong>
                </p>{" "}
              </span>
            </div>
            <div style={{ flex: "1" }}></div>
            <div>
              <img
                className="homeImgback3"
                src={GroupBossRoot}
                alt="GroupBossLogo Logo"
              />
            </div>
          </Row>
        </Col>
        <Col
          md={8}
          sm={12}>
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Col
              xl={5}
              lg={6}
              md={7}
              sm={9}>
              <div className="signupForm">
                <h3 className="signin">Please Reset Your Password</h3>
                <br></br>
                <Form onSubmit={handleReset}>
                  <div className="form-group">
                    <div id="input_containerp">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        name="password"
                        onChange={onTextChange}
                        id="inputP"
                        className="form-control showPass"
                        placeholder="Enter New Password"
                        value={password}
                      />
                      <img
                        src={
                          isPasswordVisible
                            ? hidePasswordIcon
                            : showPasswordIcon
                        }
                        alt="show password"
                        style={{ height: "27px", width: "27px" }}
                        id="input_img"
                        title="Show Password"
                        onClick={toggleShow}
                      />
                    </div>
                    <span className="error-message">
                      {state.saveErrors["password"]}
                    </span>
                  </div>
                  <div className="form-group">
                    <div id="input_containerp">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        name="confirmPassword"
                        onChange={onTextChange}
                        id="inputPasswordMatch"
                        className="form-control showPass"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                      />
                      <img
                        src={
                          isPasswordVisible
                            ? hidePasswordIcon
                            : showPasswordIcon
                        }
                        alt="show password"
                        style={{ height: "27px", width: "27px" }}
                        id="input_img"
                        title="Show Password"
                        onClick={toggleShow}
                      />
                    </div>
                    <span className="error-message">
                      {state.saveErrors["confirmPassword"]}
                    </span>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block groupboss">
                      Reset Password
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ResetPassword;
