import { connect } from "react-redux";

// Component
import Table from "./Table";

// Actions
import { getGroupList } from "../Dashboard/dashboardActions";
import { getTableData, getTableDataByDate, selectGroup } from "./tableActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  tableData: state.table.data,
  groupList: state.dashboard.groupList,
  selectedGroup: state.table.selectedGroup,
  auth: state.auth,
  isLoading: state.table.isLoading,
  redirect: state.dashboard.redirect,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getTableData: (details) => dispatch(getTableData({ ...details })),
  getGroupList: () => dispatch(getGroupList()),
  selectGroup: (details) => dispatch(selectGroup({ ...details })),
  getTableDataByDate: (details) => dispatch(getTableDataByDate({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Table);
