import { connect } from "react-redux";

// Component
import Billing from "./Billing";

// Store props to map with current state
const mapStateToProps = state => ({
  isLoading: state.resetPassword.isLoading
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
   //changePassword: details => dispatch(changePassword({ ...details }))
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Billing);
