import {
    EDIT_REQUEST,
    EDIT_REQUEST_SUCCESS,
    EDIT_REQUEST_FAILURE
  } from "../../utils/constants";
  
  import { createNotification } from "../../utils/notificationManager";
  
  // ------------------------------------
  // Action handler methods
  // ------------------------------------
  export const handleLoginRequest = (state, action) => {
    return {
      ...state,
      errorMessage: "",
      isLoading: true
    };
  };
  // handle the login request and redirect to the next page
  // check in login component
  export const handleLoginRequestSuccess = (state, action) => {
    const payload = action.payload;
    // if (payload.status === 200) {
    //   if (payload.data.confirmed === false) {
    //     createNotification("error", "Please, confirm your account first", 3000);
    //     return {
    //       ...state,
    //       isLoading: false,
    //       errorMessage: "",
    //       notVerified: true,
    //       auth: payload.data,
    //       redirect: true
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       isLoading: false,
    //       errorMessage: "",
    //       auth: payload.data,
    //       redirect: true
    //     };
    //   }
    // }
    
    return {
      ...state,
      isLoading: false
    };
  };
  
  export const handleLoginRequestFailure = (state, action) => {
    const {
      payload: { response }
    } = action;
    if (response === undefined) {
      createNotification("error", "No internet connection found", 2000);
      return {
        ...state,
        auth: undefined,
        isLoading: false,
        redirect: false
      };
    } else {
      if (response.data && response.data.detail) {
        return {
          ...state,
          auth: undefined,
          isLoading: false,
          redirect: false,
          errorMessage: response.data.detail
        };
      } else {
        return {
          ...state,
          auth: undefined,
          isLoading: false,
          redirect: false
        };
      }
    }
  };
  
  // export const handleLoginRedirectReset = (state, action) => {
  //   return {
  //     ...state,
  //     isLoading: false,
  //     redirect: false,
  //     notVerified: false
  //   };
  // };
  
  // export const handleRegisterAuthSuccess = (state, action) => {
  //   return {
  //     ...state,
  //     auth: action.payload.data.data.auth
  //   };
  // };
  
  // export const handleLogoutRequest = (state, action) => {
  //   const resetState = { ...state, ...initialState };
  //   return {
  //     ...resetState
  //   };
  // };
  
  // Action Handlers
  const ACTION_HANDLERS = {
    [EDIT_REQUEST]: handleLoginRequest,
    [EDIT_REQUEST_SUCCESS]: handleLoginRequestSuccess,
    [EDIT_REQUEST_FAILURE]: handleLoginRequestFailure
    // [LOGIN_REDIRECT_RESET]: handleLoginRedirectReset,
    // [REGISTER_REQUEST_SUCCESS]: handleRegisterAuthSuccess,
    // [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest
  };
  
  // default initial state
  const initialState = {
    auth: undefined,
    redirect: false,
    isLoading: false,
    notVerified: false,
    errorMessage: ""
  };
  
  export default function authReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
  }
  