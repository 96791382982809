import moment from "moment";
import { Chart } from "primereact/chart";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import GroupBossHome from "../../assets/home.png";
import GroupBossHome2 from "../../assets/home2.png";
import DropdownStyle from "../../utils/DropdownStyle";
import Loader from "../Common/MyLoader";

function NoOptionsMessage(props) {
  if (props.options.length)
    return <span className="customNoOption">No group found</span>;
}

const options = {
  legend: {
    display: false,
  },

  scales: {
    x: {
      grid: {
        color: "transparent",
      },
      border: {
        color: "#fff",
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        color: "transparent",
      },
      border: {
        color: "#fff",
      },
    },

  },
};
const options2 = {
  legend: {
    display: false,
  },

  scales: {
    x: {
      grid: {
        color: "transparent",
      },
      border: {
        color: "#fff",
      },
    },
    y: {
      beginAtZero: true,
      border: {
        color: "#fff",
      },
      grid: {
        color: "transparent",
      },
    },
  },
};

function Dashboard(props) {
  const navigate = useNavigate();
  const [state, setState] = useState({
    color1: "#52565E",
    color2: "#909497",
    isOpen: false,
    isDataVisible: true,
    selectedGroup: {
      id: "",
      name: "",
    },
    dayName: [],
    dayValue: [],
    chartValue: [],
    monthName: [],
    monthValue: [],
    chartColor: "#33cdd1",
    collectFromExtension: false,
  });

  useEffect(() => {
    const { getDashboardData, getGroupList } = props;
    getDashboardData();
    getGroupList();
  }, []);

  useEffect(() => {
    if (props.dashboardData?.lifetime?.count === 0 && !props.status) {
      setState((prevState) => ({
        ...prevState,
        collectFromExtension: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        collectFromExtension: false,
      }));
    }
  }, [props.dashboardData, props.status]);

  useEffect(() => {
    const {
      dashboardData,
      redirect,
      getDashboardData,
      getGroupwiseData,
      getGroupList,
    } = props;

    if (redirect) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_first_name");
      localStorage.removeItem("user_last_name");
      localStorage.removeItem("user_emails");
      localStorage.removeItem("user_default_email");
      localStorage.removeItem("selected_group");
      navigate("/login");
    }

    if (dashboardData) {
      if (dashboardData.lifetime.count === 0) {
        setState((prevState) => ({
          ...prevState,
          isDataVisible: false,
          chartColor: "#dfe5ea",
          dayName: [
            "1 Jan",
            "2 Jan",
            "3 Jan",
            "4 Jan",
            "5 Jan",
            "6 Jan",
            "7 Jan",
          ],
          chartValue: dashboardData,
          dayValue: [15, 20, 10, 8, 30, 20, 12],
          monthName: ["Jan", "Feb", "March", "April", "May", "Jun", "July"],
          monthValue: [10, 15, 13, 20, 14, 22, 14],
          lifetime: dashboardData.lifetime.count,
          today: dashboardData.today.count,
        }));
      } else if (dashboardData.lifetime.count > 0) {
        setState((prevState) => ({
          ...prevState,
          isDataVisible: true,
          chartColor: "#33cdd1",
          chartValue: dashboardData,
          monthName: dashboardData.last_year_by_month.map((item) => item.month),
          monthValue: dashboardData.last_year_by_month.map(
            (item) => item.count
          ),
          dayName: dashboardData.last_week_by_days.map((item) => item.date),
          dayValue: dashboardData.last_week_by_days.map((item) => item.count),
          lifetime: dashboardData.lifetime.count,
          today: dashboardData.today.count,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        isDataVisible: false,
        chartColor: "#dfe5ea",
        dayName: [
          "1 Jan",
          "2 Jan",
          "3 Jan",
          "4 Jan",
          "5 Jan",
          "6 Jan",
          "7 Jan",
        ],
        chartValue: dashboardData,
        dayValue: [15, 20, 10, 8, 30, 20, 12],
        monthName: ["Jan", "Feb", "March", "April", "May", "Jun", "July"],
        monthValue: [10, 15, 13, 20, 14, 22, 14],
        lifetime: 0,
        today: 0,
      }));
    }
  }, [props]);

  const onChangeGroup = (e) => {
    const { getGroupwiseData, getDashboardData } = props;
    setState((prevState) => ({
      ...prevState,
      selectedGroup: {
        id: e.value,
        name: e.label,
      },
    }));
    if (e.value === "all_groups") {
      getDashboardData();
    } else {
      let payload = {
        id: e.value,
      };
       getGroupwiseData(payload);
    }
  };

  const dashboardNav = (value) => {
    if (value === 1) {
      setState((prevState) => ({
        ...prevState,
        color1: "#52565E",
        color2: "#909497",
      }));
    }
    if (value === 2) {
      setState((prevState) => ({
        ...prevState,
        color1: "#909497",
        color2: "#52565E",
      }));
    }
  };

  const { groupList } = props;
  const { selectedGroup } = state;
  let groupListOptions = [];
  if (groupList && groupList.length > 0) {
    // eslint-disable-next-line
    groupList.map((group) => {
      groupListOptions.push({
        label: group.name,
        value: group.group_id,
      });
    });
  }

  const data2 = {
    labels: state.dayName,
    datasets: [
      {
        label: "Captured",
        backgroundColor: state?.chartColor,
        barThickness: 8,
        scaleLineColor: "#DFE5EA",
        data: state.dayValue,
      },
    ],
  };

  const lineStylesData = {
    labels: state.monthName,

    datasets: [
      {
        label: "Captured",
        data: state.monthValue,
        fill: true,
        lineTension: 0,
        borderColor: "#fff",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "#e9ffff",
        pointBackgroundColor: state.chartColor,
        pointBorderWidth: 6,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 7,
        pointHitRadius: 10,
        backgroundColor: state.chartColor,
      },
    ],
  };


  return (
    <div>
      <Loader open={props.isLoading} />

      {state.collectFromExtension && (
        <Row>
          <Col md={12}>
            <div className="nodData">Please collect data from extension</div>
          </Col>
        </Row>
      )}

      {props?.status && (
        <Row>
          <Col md={12}>
            <div className="nosubs">
              <span>
                {props.status === "inactive"
                  ? "Please subscribe to a plan to see data"
                  : "Your subscription is paused"}
              </span>
              <Link
                to="/user/subscription"
                state={{
                  from: "dashboard",
                  subs_modal: props.status === "inactive" ? true : false,
                }}
                className="dashboard_subscribe_button">
                {props.status === "inactive" ? "Subscribe" : "Resume"}
              </Link>
            </div>
          </Col>
        </Row>
      )}
      <div style={{ padding: "20px 5%" }}>
        {" "}
        <div className="dashboardHeader">
          <div>
            <span className="homeHead">Dashboard</span>
          </div>
          <div>
            <Select
              isSearchable
              placeholder="Search lead overview by group name"
              value={
                selectedGroup && selectedGroup.id !== ""
                  ? {
                      label: selectedGroup.name,
                      value: selectedGroup.id,
                    }
                  : null
              }
              options={groupListOptions}
              onChange={(e) => onChangeGroup(e)}
              styles={DropdownStyle(true)}
              components={{ NoOptionsMessage }}
            />
          </div>
        </div>
        <Row style={{ justifyContent: "center", marginTop: "30px" }}>
          <Col
            style={{ textAlign: "center" }}
            lg={6}
            xl={7}
            md={12}>
            <Row>
              <Col
                lg={6}
                md={6}
                sm={12}>
                <Card className="dashboard-card">
                  <CardHeader className="dashboard-card-header">
                    <p className="homeHead2">Total Lead (Email) Captured</p>
                    <p
                      className="homeHead3"
                      style={{ marginTop: "-15px" }}>
                      Since February 5, 2020
                    </p>
                  </CardHeader>
                  <CardBody className="dashboard-card-body">
                    <div className="homeImg">
                      {state.isDataVisible ? (
                        <>
                          <img
                            src={GroupBossHome}
                            className="homeImg"
                            style={{ width: "100%" }}
                            alt="GroupBoss Home"
                          />
                          <div className="numberOfScrap2">{state.lifetime}</div>
                        </>
                      ) : (
                        <>
                          <img
                            src={GroupBossHome2}
                            className="homeImg"
                            style={{ width: "100%" }}
                            alt="GroupBoss Home"
                          />
                          <div className="numberOfScrap2">0</div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}>
                <Card className="dashboard-card">
                  <CardHeader className="dashboard-card-header">
                    <p className="homeHead2">Total Lead (Email) Captured</p>
                    <p
                      className="homeHead3"
                      style={{ marginTop: "-15px" }}>
                      Previous 24 Hours
                    </p>
                  </CardHeader>
                  <CardBody className="dashboard-card-body">
                    <div className="homeImg">
                      {state.isDataVisible ? (
                        <>
                          <img
                            src={GroupBossHome}
                            className="homeImg"
                            style={{ width: "100%" }}
                            alt="GroupBoss Home"
                          />
                          <div className="numberOfScrap2">{state.today}</div>
                        </>
                      ) : (
                        <>
                          <img
                            src={GroupBossHome2}
                            className="homeImg"
                            style={{ width: "100%" }}
                            alt="GroupBoss Home"
                          />
                          <div className="numberOfScrap2">0</div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: "30px" }}>
              <Col md={12}>
                <Card className="dashboard-card">
                  <CardHeader className="dashboard-card-header">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <p className="homeHead2">Total Lead (Email) Captured</p>
                      <p className="homeHead3">
                        *From today to previous 7 days
                      </p>
                    </div>
                  </CardHeader>
                  <CardBody className="dashboard-card-body">
                    <div className="firstChart">
                      <br />
                      <Chart
                        height={"90%"}
                        type="bar"
                        data={data2}
                        options={options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            style={{
              textAlign: "center",
            }}
            lg={6}
            xl={5}
            md={12}>
            <Card
              className="dashboard-card yearly"
              style={{ height: "100%" }}>
              <CardHeader className="dashboard-card-header">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>
                    <p className="homeHead2">Total Lead (Email) Captured</p>
                    <p
                      className="homeHead3"
                      style={{ marginTop: "-15px" }}>
                      {`${moment()
                        .subtract(1, "year")
                        .format("MMMM YYYY")} to ${moment().format(
                        "MMMM YYYY"
                      )}`}
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "40px",
                        padding: "0px 10px",
                        color: "#52575f",
                      }}>
                      {state.lifetime && !props?.status ? state.lifetime : "0"}
                    </p>
                  </div>
                </div>
              </CardHeader>
              <CardBody
                style={{ height: "100%" }}
                className="dashboard-card-body">
                <Row
                  style={{
                    height: "100%",
                    alignItems: "flex-end",
                    margin: "0",
                  }}>
                  <div style={{ width: "100%" }}>
                    <Chart
                      className="secondChart"
                      height={"160%"}
                      type="line"
                      data={lineStylesData}
                      options={options2}
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Dashboard;
