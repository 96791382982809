import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./components/Login/loginReducer";
import forgotPasswordReducer from "./components/ForgotPassword/forgotPasswordReducer";
import resetPasswordReducer from "./components/ResetPassword/resetPasswordReducer";
import dashboardReducer from "./components/Dashboard/dashboardReducer";
import tableReducer from "./components/Table/tableReducer";
import accountSettingsReducer from "./components/AccountSettings/AccountSettingsReducer";

import { reducer as formReducer } from "redux-form";
import subscriptionReducer from "./components/Subscription/subscriptionReducer";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["auth"]
};

export default history =>
  combineReducers({
    // router: connectRouter(history),
    auth: persistReducer(authPersistConfig, authReducer),
    form: formReducer,
    forgot: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    dashboard: dashboardReducer,
    table: tableReducer,
    accountSettings: accountSettingsReducer,
    subscription: subscriptionReducer
  });
