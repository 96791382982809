import { connect } from "react-redux";

// Component
// import Dashboard from '../../views/Dashboard';
import Dashboard from "./Dashboard.jsx";

// Actions
import {
  getDashboardData,
  getGroupList,
  getGroupwiseData,
} from "./dashboardActions";
import { getSubscriptionPlan } from "../Subscription/subscriptionActions.js";

// Store props to map with current state
const mapStateToProps = (state) => {
  if (
    state.dashboard?.subscriptionStatus === "inactive" ||
    state.dashboard?.subscriptionStatus === "paused"
  ) {
    return {
      isLoading: state.dashboard.isLoading,
      status: state.dashboard.subscriptionStatus,
      redirect: state.dashboard.redirect,
    };
  }
  return {
    dashboardData: state.dashboard.data,
    groupList: state.dashboard.groupList,
    // status: "inactive",
    isLoading: state.dashboard.isLoading,
    redirect: state.dashboard.redirect,
  };
};

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getDashboardData: (data) => dispatch(getDashboardData(data)),
  getGroupList: () => dispatch(getGroupList()),
  getGroupwiseData: (details) => dispatch(getGroupwiseData({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
