// Redux integrated routes
import AccountSettings from "../AccountSettings";
import Billing from "../Billing";
import ChangePassword from "../ChangePaswword";
import Dashboard from "../Dashboard";
import GettingStarted from "../GettingStarted/GettingStarted";
import GettingStartedVideo from "../GettingStarted/GettingStartedVideo";
import MyPlan from "../Subscription";
import Table from "../Table";

const routes = [
  {
    path: "dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/user",
  },
  {
    path: "data-table",
    name: "Data Table",
    component: Table,
    layout: "/user",
  },
  {
    path: "change-password",
    name: "Change Password",
    component: ChangePassword,
    layout: "/user",
  },
  {
    path: "account-settings",
    name: "Account Settings",
    component: AccountSettings,
    layout: "/user",
  },
  {
    path: "billing",
    name: "Billing",
    component: Billing,
    layout: "/user",
  },
  {
    path: "getting-started",
    name: "GettingStarted",
    component: GettingStarted,
    layout: "/user",
  },
  {
    path: "getting-started-video",
    name: "GettingStartedVideo",
    component: GettingStartedVideo,
    layout: "/user",
  },
  {
    path: "subscription",
    name: "Subscription",
    component: MyPlan,
    layout: "/user",
  },
];

export default routes;
