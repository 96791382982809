import React from "react";
import { Col, Row } from "reactstrap";
import Loader from "../Common/MyLoader";
import checkIcon from "../../assets/check.svg";

function Billing(props) {
  return (
    <div style={{ padding: "61px 60px 31px 60px" }}>
      <Loader open={props.isLoading} />
      <div
        style={{
          backgroundColor: "#FFFFFF",
          fontFamily: "nova",
          color: "#33475B",
          opacity: "1",
          borderRadius: "19px",
        }}
      >
        <Row style={{ paddingTop: "60px", marginBottom: "30px" }}>
          <Col md={1} sm={2}>
            <div style={{ marginLeft: "60px" }}>
              <span style={{ fontWeight: "bold", fontSize: "24px" }}>
                Billing
              </span>
            </div>
          </Col>
          <Col md={11} sm={10}>
            <div
              style={{
                marginRight: "60px",
                marginLeft: "60px",
                textAlign: "end",
              }}
            >
              <span style={{ fontSize: "20px" }}>
                Your are subscribed to{" "}
              </span>
              <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                Monthly (Starter)
              </span>
            </div>
          </Col>
        </Row>
        <hr />
        <div className="pricing">
          {/* Yearly */}
          <div className="div1">
            <div className="div1Top">
              <div className="popular">popular</div>
              <div className="yearlyPopular">Yearly Popular (Influencer)</div>
              <div className="dollar">$99</div>
              <div className="trial">
                <span>7 days free trial</span>
              </div>
              <div>
                <button className="trialBtn">Start Free Trial</button>
              </div>
            </div>
            <div className="div1Bottom">
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Add up to 5 Facebook groups
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Access to Googlesheet configuration
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Unlimited auto-responders
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Lifetime update to software
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Export data from dashboard in CSV format
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Create FB custom and lookalike audience
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  VIP Facebook group for users
                </span>
              </div>
            </div>
          </div>
          {/* LTD */}
          <div className="div1">
            <div className="div1Top">
              <div className="yearlyPopular">Mega Plan Lifetime</div>
              <div className="dollar">$299</div>
              <div>
                <button className="purchaseBtn">Purchase</button>
              </div>
            </div>
            <div className="div1Bottom">
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Unlimited Facebook groups
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Access to Googlesheet configuration
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Unlimited auto-responders
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Lifetime update to software
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Export data from dashboard in CSV format
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  Create FB custom and lookalike audience
                </span>
              </div>
              <div className="textStyle">
                <span>
                  <img
                    src={checkIcon}
                    alt="Checked"
                    title="checked"
                    className="tickImage"
                  />
                  VIP Facebook group for users
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Billing;
