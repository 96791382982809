//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  REQUEST_DASHBOARD_DATA,
  REQUEST_DASHBOARD_DATA_FAILURE,
  REQUEST_DASHBOARD_DATA_SUCCESS,
  REQUEST_GROUPLIST_DATA,
  REQUEST_GROUPLIST_DATA_FAILURE,
  REQUEST_GROUPLIST_DATA_SUCCESS,
  REQUEST_GROUPWISE_DATA,
  REQUEST_GROUPWISE_DATA_FAILURE,
  REQUEST_GROUPWISE_DATA_SUCCESS,
} from "../../utils/constants";

export function* groupListDataRequest() {
  try {
    const url = `grouplist/`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: REQUEST_GROUPLIST_DATA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: REQUEST_GROUPLIST_DATA_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardDataRequest({ payload }) {
  try {
    const url = `dashboard/`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: REQUEST_DASHBOARD_DATA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: REQUEST_DASHBOARD_DATA_FAILURE,
      payload: err,
    });
  }
}

export function* groupwiseDataRequest({ payload }) {
  try {
    const url = `dashboard/${payload.id}/`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: REQUEST_GROUPWISE_DATA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: REQUEST_GROUPWISE_DATA_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(REQUEST_DASHBOARD_DATA, dashboardDataRequest),
    takeLatest(REQUEST_GROUPLIST_DATA, groupListDataRequest),
    takeLatest(REQUEST_GROUPWISE_DATA, groupwiseDataRequest),
  ]);
}
