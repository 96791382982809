//Effects
import { put, takeLatest } from "redux-saga/effects"; //axios
// import axios from 'axios';
//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  EDIT_REQUEST,
  EDIT_REQUEST_SUCCESS,
  EDIT_REQUEST_FAILURE
} from "../../utils/constants";

//Handle login request
export function* editRequest({ payload }) {
  try {
    let url = "auth/emails/";
    let details = {
      email: payload.email
    };
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: EDIT_REQUEST_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: EDIT_REQUEST_FAILURE,
      payload: err
    });
  }
}

// Watches for the LOGIN_REQUEST action type
// WITH the action we dispatched
function* editEmailRequestWatcher() {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(EDIT_REQUEST, editRequest);
}

export default editEmailRequestWatcher;
