import { CHANGE_PASSWORD_REQUEST, EDIT_REQUEST } from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function editRequest(value) {
  return {
    type: EDIT_REQUEST,
    payload: value
  };
}

export function changePassword(value) {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: value
  };
}

export const actions = {
  editRequest,
  changePassword
};
