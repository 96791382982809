import React, { useEffect, useState } from "react";

// reactstrap components
import { Link } from "react-router-dom";
import { Col, Form, FormGroup, Row } from "reactstrap";
import GroupBossLogo from "../../assets/logo.png";
import GroupBossRoot from "../../assets/root.png";
import { validateEmail } from "../../utils/helpers";
import { createNotification } from "../../utils/notificationManager";
import Loader from "../Common/MyLoader";

import { useNavigate } from "react-router-dom";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { forgot } = props;

  useEffect(() => {
    let token = localStorage.getItem("access_token");
    if (token != null) {
      navigate("/user/dashboard");
    }
  }, []);

  const onTextChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    const { forgotPassword } = props;
    if (email === "") {
      createNotification("error", "Email required", 3000);
    } else if (!validateEmail(email)) {
      createNotification("error", "Enter a valid email", 3000);
    } else {
      let payload = {
        email: email,
      };
      forgotPassword(payload);
    }
  };

  return (
    <div className="login-page">
      <Loader open={forgot.isLoading} />
      <Row className="align-items-center">
        <Col
          md={4}
          sm={12}
          className="leftbar">
          <Row
            style={{
              flexDirection: "column",
              justifyContent: "space-around",
              marginLeft: "0",
            }}
            className="leftbarpadding">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="leftbar-logo-text">
              <img
                src={GroupBossLogo}
                className="homeImgback2"
                alt="GroupBossLogo Logo"
              />
              <br />
              <span className="sloganLogin">
                Raining Sales From Your Facebook Group{" "}
                <p className="sloganLogin2">
                  <strong>Like a Boss!</strong>
                </p>{" "}
              </span>
            </div>
            <div style={{ flex: "1" }}></div>
            <div>
              <img
                className="homeImgback3"
                src={GroupBossRoot}
                alt="GroupBossLogo Logo"
              />
            </div>
          </Row>
        </Col>
        <Col
          md={8}
          sm={12}>
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Col
              xl={5}
              lg={6}
              md={6}
              sm={9}>
              <div className="loginForm">
                <h3 className="signin">Reset Password</h3>
                <br></br>
                <Form
                  className="form"
                  onSubmit={(e) => handleForgotPassword(e)}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      maxLength="255"
                      onChange={(e) => onTextChange(e)}
                      placeholder="Enter email"
                      value={email}
                    />
                  </div>
                  <FormGroup>
                    <button
                      type="submit"
                      className="btn btn-primary btn-block groupboss">
                      Reset
                    </button>
                  </FormGroup>
                  <div className="signupInvitation">
                    <p>
                      {" "}
                      <Link
                        to="/login"
                        style={{ color: "#3CCBCF", fontWeight: "700" }}>
                        Back to Login
                      </Link>
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
