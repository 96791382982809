import React from "react";
import checkIcon from "../../assets/img/icons/tick_2.svg";
import { StyledButton } from "../../styledComponents/buttons";
import { InnerWrapper, PackageWrapper } from "../../styledComponents/common";
export const Package = ({ data, onclick }) => {
  const [selected, setSelected] = React.useState("");
  return (
    <PackageWrapper
      borderColor={data.primaryPackageColor}
      blur={data.isCurrent}>
      {data.isCurrent && (
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
            width: "100%",
          }}>
          <StyledButton
            backgroundColor={data.primaryPackageColor}
            padding="15px 30px"
            position="absolute"
            fontSize="18px">
            Current Plan
          </StyledButton>
        </span>
      )}
      <InnerWrapper blur={data.isCurrent}>
        <span
          style={{
            color: data.primaryPackageColor,
            fontWeight: "600",
            fontSize: "20px",
          }}>
          {data.name}
        </span>
        <div
          style={{
            backgroundColor: data.primaryPackageColor,
          }}
          className="price-box">
          <span>$ {data.price}</span>
          <p
            style={{
              fontSize: "15px",
              color: "#fff",
              fontWeight: "400",
            }}>
            {data.priceDesc}
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            width: "100%",
            padding: "28px 35px",
          }}
          className="feature-container">
          {data.features.map((item, index) => (
            <div
              key={index}
              className="feature-item">
              <img
                src={checkIcon}
                alt="check"
                className="check-icon"
              />

              <span
                style={{
                  color: "#52565e",
                }}>
                {item}
              </span>
            </div>
          ))}
        </div>

        <StyledButton
        onClick={onclick}
          backgroundColor={data?.primaryPackageColor}
          textColor="#fff"
          fontSize="16px"
          fontWeight="500"
          padding="10px 20px"
          width="90%"
          hoverBackgroundColor={data.primaryPackageColor + "98"}>
          Choose Plan
        </StyledButton>
      </InnerWrapper>
    </PackageWrapper>
  );
};
