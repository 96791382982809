import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_STATE_RESET,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST_SUCCESS,
  CHANGE_PASSWORD_REQUEST_FAILURE,
  LOGIN_PROFILE_CREATED_RESET
} from "../../utils/constants";
import { createNotification } from "../../utils/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleResetRequest = (state, action) => {
  return {
    ...state,
    isLoading: true
  };
};

export const handleResetRequestSuccess = (state, action) => {
  const {
    payload: { data }
  } = action;
  if (data.status === 200) {
    createNotification("success", "Password reset success", 2000);
    return {
      ...state,
      isLoading: false,
      redirect: true
    };
  }
  return {
    ...state,
    isLoading: false
  };
};

export const handleResetRequestFailure = (state, action) => {
  const {
    payload: { response }
  } = action;
  if (response === undefined) {
    createNotification("error", "No internet connection found", 3000);
    return {
      ...state,
      isLoading: false,
      redirect: false
    };
  }
  if (response.data) {
    createNotification("error", response.data.failure, 2000);
  }
  return {
    ...state,
    isLoading: false,
    redirect: false
  };
};

export const handleResetPasswordRedirectReset = (state, action) => {
  return {
    ...state,
    isLoading: false,
    redirect: false
  };
};

export const handleChangePasswordRequest = (state, action) => {
  return {
    ...state,
    isLoading: true
  };
};

export const handleChangePasswordRequestSuccess = (state, action) => {
  const {
    payload: { data }
  } = action;
  if (data.status === 200) {
    createNotification("success", "Password changed successfully", 2000);
    return {
      ...state,
      passwordChanged: true,
      isLoading: false
    };
  }
  return {
    ...state,
    isLoading: false
  };
};

export const handleChangePasswordRequestFailure = (state, action) => {
  const {
    payload: { response }
  } = action;
  if (response === undefined) {
    createNotification("error", "No internet connection found", 3000);
    return {
      ...state,
      isLoading: false
    };
  }
  if (response.data) {
    if (response.data.old_password) {
      createNotification(
        "error",
        "Current Password is incorrect. Please provide the correct password.",
        4000
      );
    } else {
      createNotification("error", "Please try again", 3000);
    }
    return {
      ...state,
      passwordChanged: false,
      isLoading: false
    };
  }
  return {
    ...state,
    isLoading: false
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [RESET_PASSWORD]: handleResetRequest,
  [RESET_PASSWORD_SUCCESS]: handleResetRequestSuccess,
  [RESET_PASSWORD_FAILURE]: handleResetRequestFailure,
  [RESET_PASSWORD_STATE_RESET]: handleResetPasswordRedirectReset,
  [CHANGE_PASSWORD_REQUEST]: handleChangePasswordRequest,
  [CHANGE_PASSWORD_REQUEST_SUCCESS]: handleChangePasswordRequestSuccess,
  [CHANGE_PASSWORD_REQUEST_FAILURE]: handleChangePasswordRequestFailure,
  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest
};

// default initial state
const initialState = {
  redirect: false,
  isLoading: false,
  passwordChanged: false
};

export default function resetPasswordReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
