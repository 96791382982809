import { all, fork } from "redux-saga/effects";
import loginSagas from "./components/Login/loginSagas";
import forgotPasswordSagas from "./components/ForgotPassword/forgotPasswordSagas";
import resetPasswordSagas from "./components/ResetPassword/resetPasswordSagas";
import dashboardSagas from "./components/Dashboard/dashboardSagas";
import tableSagas from "./components/Table/tableSagas";
import accountSettingsSagas from "./components/AccountSettings/AccountSettingsSagas";
import subscriptionSagas from "./components/Subscription/subscriptionSagas";

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(loginSagas),
    fork(forgotPasswordSagas),
    fork(resetPasswordSagas),
    fork(dashboardSagas),
    fork(tableSagas),
    fork(accountSettingsSagas),
    fork(subscriptionSagas)
  ]);
}
