// import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

//default css files
import "bootstrap/dist/css/bootstrap.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova-light/theme.css";
import "react-dates/lib/css/_datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import "./index.css";

//Store
import { store } from "./configureStore";

//Persist

//Provider
import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";

//react-dates
import "react-dates/initialize";

import App from "./App";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PGCX4BBK",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
  </Provider>
);
