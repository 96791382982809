import React, { useState } from "react";
import { Button } from "reactstrap";
import { DateRangePicker } from "react-dates";

const ArrowIcon = () => (
  <div style={{ paddingTop: "8px", color: "#808080" }}>to</div>
);

function ReactDateRangePicker(props) {
  const [focusedInput, setFocusedInput] = useState(null);

  const getCalendarInfo = () => {
    return (
      <div className="date-range-footer">
        <div>
          <Button
            className="date-range-picker-button"
            style={{ padding: "2px 15px", marginRight: "25px" }}
            onClick={props.onDateSelect}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  return (
    <DateRangePicker
      startDate={props.startDate}
      startDateId={props.startDateId}
      endDate={props.endDate}
      endDateId={props.endDateId}
      onDatesChange={({ startDate, endDate }) =>
        props.onDatesChange({ startDate, endDate })
      }
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      showDefaultInputIcon
      inputIconPosition="after"
      isOutsideRange={() => false}
      displayFormat={props.dateDisplayFormat}
      renderCalendarInfo={() => getCalendarInfo()}
      calendarInfoPosition="bottom"
      keepOpenOnDateSelect
      customArrowIcon={<ArrowIcon />}
      anchorDirection="right"
    />
  );
}

export default ReactDateRangePicker;
