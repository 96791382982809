import axios from "axios";
import { useNavigate } from "react-router-dom";

const reqData = {
  method: "GET",
  url: "",
  queryParams: {},
  formData: {},
  bodyParams: {},
  pathParams: [],
  data: {},
};

function makeHeaders() {
  const headers = {
    "Content-Type": "application/json",
  };
  return headers;
}
/*eslint-disable */
function makeHeaderWithToken(token) {
  const headers = {
    Authorization: `${"Bearer" + " "}${token}`,
    "Content-Type": "application/json",
  };
  return headers;
}

let subscribers = [];

const handleLogout = () => {
  localStorage.clear()
  subscribers = []
  window.location.reload();

}

const addSubscriber = (callback) => {
  subscribers.push(callback);
};
async function setToken(key, value) {
  localStorage.setItem(key, value);
  return true;
}

async function getToken(key) {
  return localStorage.getItem(key);
}

function onAccessTokenFetched(accessToken) {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach((callback) => {
    const result = callback(accessToken);
  });
  subscribers = [];
}

const resetTokenAndReattemptRequest = async (error) => {
  try {
    // const { errorResponse } = error;
    const refreshToken = await getToken("refresh_token");
    if (!refreshToken) {
      handleLogout()
      return Promise.reject(error);
    }
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        error.config.headers.Authorization = "Bearer " + accessToken;
        resolve(axios(error.config));
      });
    });

    if (!window.isRefreshingToken) {
      window.isRefreshingToken = true;
      try {
        const response = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/api/auth/token/refresh/`,
          data: {
            refresh_token: refreshToken,
          },
        });
        if (!response.data) {
          handleLogout()
          window.isRefreshingToken = false;
          return Promise.reject(error);
        }
        const { access_token, refresh_token } = response.data;
        await setToken("access_token", access_token);
        await setToken("refresh_token", refresh_token);
        window.isRefreshingToken = false;
        onAccessTokenFetched(access_token);
      } catch (err) {
        handleLogout()
        window.isRefreshingToken = false;
        return Promise.reject(error);
      }
    }
    return retryOriginalRequest;
  } catch (err) {
    handleLogout()
    window.isRefreshingToken = false;

    return Promise.reject(error);
  }
};

export function defaultApi(url, method, details) {
  const token = localStorage.getItem("access_token");

  axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.withCredentials = true;

  const URL = `${process.env.REACT_APP_API_URL}/api/`.toString();

  const api = axios.create({
    baseURL: URL,
    headers: token ? makeHeaderWithToken(token) : makeHeaders(),
  });

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config, response } = error;
      if (response?.status === 401) {
        return resetTokenAndReattemptRequest(error);
      }
      return Promise.reject(error);
    }
  );

  let requestDetails = { ...reqData };
  requestDetails.url = url;
  requestDetails.method = method;
  requestDetails.data = details;
  return api(requestDetails).then((response) => response);
}

export function noHeaderApi(url, method, details) {
  axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.withCredentials = true;

  const URL = `${process.env.REACT_APP_API_URL}/api/`.toString();

  const api = axios.create({
    baseURL: URL,
  });

  let requestDetails = { ...reqData };
  requestDetails.url = url;
  requestDetails.method = method;
  requestDetails.data = details;
  return api(requestDetails).then((response) => response);
}
