import {
  CANCEL_PLAN,
  GET_SUBSCRIPTION_PLAN,
  GET_SUBSCRIPTION_PLAN_FAILURE,
  GET_SUBSCRIPTION_PLAN_SUCCESS,
  PAUSE_SUBSCRIPTION,
  PAUSE_SUBSCRIPTION_FAILURE,
  PAUSE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN_FAILURE,
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
} from "../../utils/constants";

import { createNotification } from "../../utils/notificationManager";

export const handleGetSubscriptionPlanRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    redirect: false,
  
  };
};

export const handleGetSubscriptionPlanRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      redirect: false,
      myPlan: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
  
  };
};

export const handleGetSubscriptionPlanRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;

  let token = localStorage.getItem("access_token");
  if (token === null) {
    return {
      ...state,
      isLoading: false,
      redirect: true,
      data: null,
    };
  }
  
  return {
    ...state,
    isLoading: false,
    redirect: false,
    myPlan: null,
  };
};

export const handleUpdateSubscriptionPlanRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    redirect: false,
  
  };
};

export const handleUpdateSubscriptionPlanRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && !data?.data?.error?.success) {
    createNotification("warning", data?.data?.error?.error?.message, 2000);
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
  
    myPlan: {
      ...state.myPlan,
      paddle_plan_id: data?.data?.response?.plan_id,
      unit_price: data?.data?.response?.next_payment?.amount,
    },
  };
};

export const handleUpdateSubscriptionPlanRequestFailure = (state, action) => {
  createNotification("warning", "Please try again later", 2000);
  return {
    ...state,
    isLoading: false,
    redirect: false,
  
  };
};

export const handleCancelSubscriptionPlanRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    redirect: false,
  
    myPlan: {
      ...state.myPlan,
      status: "deleted",
    },
  };
};

export const handlePauseSubscriptionReqest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    redirect: false,
  
  };
};

export const handlePauseSubscriptionSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  let updatedState = {
    ...state,
    isLoading: false,
    redirect: false,
  
  };

  if (data?.data?.error?.error?.message) {
    createNotification("warning", data?.data?.error?.error?.message, 2000);
  } else {
    updatedState = {
      ...updatedState,
      myPlan: {
        ...state.myPlan,
        status:
          data?.data?.status === "active" ? "active" : state.myPlan?.status,
        payment_information: {
          ...state.myPlan?.payment_information,
          paused_at:
            data?.data?.status === "paused"
              ? new Date().toLocaleDateString()
              : null,
          paused_from:
            data?.data?.status === "paused"
              ? state?.myPlan?.payment_information?.next_payment?.date
              : null,
        },
      },
    };
  }
  return updatedState;
};
export const handlePauseSubscriptionfailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

const ACTION_HANDLERS = {
  [GET_SUBSCRIPTION_PLAN]: handleGetSubscriptionPlanRequest,
  [GET_SUBSCRIPTION_PLAN_SUCCESS]: handleGetSubscriptionPlanRequestSuccess,
  [GET_SUBSCRIPTION_PLAN_FAILURE]: handleGetSubscriptionPlanRequestFailure,
  [UPDATE_SUBSCRIPTION_PLAN]: handleUpdateSubscriptionPlanRequest,
  [UPDATE_SUBSCRIPTION_PLAN_SUCCESS]:
    handleUpdateSubscriptionPlanRequestSuccess,
  [UPDATE_SUBSCRIPTION_PLAN_FAILURE]:
    handleUpdateSubscriptionPlanRequestFailure,
  [CANCEL_PLAN]: handleCancelSubscriptionPlanRequest,
  [PAUSE_SUBSCRIPTION]: handlePauseSubscriptionReqest,
  [PAUSE_SUBSCRIPTION_SUCCESS]: handlePauseSubscriptionSuccess,
  [PAUSE_SUBSCRIPTION_FAILURE]: handlePauseSubscriptionfailure,
};

const initialState = {
  isLoading: false,
  redirect: false,
  myPlan: null,
};

export default function subscriptionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
