import React, { useState } from "react";
import { StyledButton } from "../../styledComponents/buttons";

import planActive from "../../assets/img/subscription-status-active.svg";
import CardContainer from "./CardContainer";
import { CapitalizedH3 } from "../../styledComponents/common";
import { getCurrencySymbol, getPlanName, getPlanType, reformatDate } from "../../utils/helpers";
import PossibleReasonBox from "./PossibleReasonBox";


// You have insufficient funds in your account
// Your payment card has expired
// There's a problem with your bank

const possibleReasons = [
  {
    color: "#FFC107",
    text: "You have insufficient funds in your account",
  },
  {
    color: "#FFC107",
    text: "Your payment card has expired",
  },
  {
    color: "#FFC107",
    text: "There's a problem with your bank",
  },
];


const CurrentPlan = ({ modal, setModal, planInfo, resumeSubscription }) => {
  return (
    <div className="subscontent">
      <div
        style={{ padding: "2.5rem 2.5rem 0rem 2.5rem" }}
        className="innerTop"
      >
        <p className="subheader">Current Plan</p>
        <div className="subcenter">
          <div className="leftsubcontent">
            <div className="subs-rate-container">
              <CapitalizedH3>
                {getPlanName(planInfo?.paddle_plan_id)}
              </CapitalizedH3>
              <span className="subs-rate">
                {getCurrencySymbol(planInfo?.currency) +
                  planInfo?.unit_price +
                  getPlanType(planInfo?.paddle_plan_id)}
              </span>
            </div>
            <div
              style={{
                margin: "20px 0",
                fontSize: "16px",
              }}
            >
              <p
                style={{
                  color: "#52575f",
                }}
              >
                Status
              </p>
              <span>
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "10px",
                  }}
                  src={planActive}
                  alt="plan active"
                  width="100%"
                />
                {planInfo?.status === "trialing"
                  ? "Trial"
                  : planInfo?.status === "active"
                  ? "Active"
                  : planInfo?.status === "past_due"
                  ? "Past Due"
                  : "Active"}
              </span>
              <span
                style={{
                  display: planInfo?.status === "past_due" ? "block" : "none",
                  marginTop: "20px",
                }}
              >
                <p style={{ 
                  color: "#52575f",
                  fontSize: "15px",
                  marginBottom: "5px"
                   }}>
                  We can see that your subscription has gone past due. It could
                  be because-
                </p>
                {possibleReasons.map((reason, index) => (
                  <span
                    className="possible-reasons"
                    key={index}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: reason.color,
                        borderRadius: "50%",
                      }}
                    />
                    <span>{reason.text}</span>
                  </span>
                ))}
              </span>
            </div>
            <div>
              <p style={{ color: "#52575f" }}>
                Member Since{" "}
                <span style={{ color: "#000" }}>
                  {reformatDate(planInfo?.created_at)}
                </span>{" "}
              </p>
              {!planInfo?.payment_information ? (
                <p>{""}</p>
              ) : planInfo?.payment_information?.next_payment?.date &&
                (planInfo?.payment_information?.paused_from === null ||
                  planInfo?.payment_information?.paused_from === undefined) ? (
                <p style={{ color: "#52575f" }}>
                  Next Billing Period{" "}
                  <span style={{ color: "#000" }}>
                    {reformatDate(
                      planInfo?.payment_information?.next_payment?.date
                    )}
                  </span>{" "}
                </p>
              ) : (
                planInfo?.payment_information?.paused_at && (
                  <p style={{ color: "#52575f" }}>
                    Subscription paused at{" "}
                    <span style={{ color: "#000" }}>
                      {" "}
                      {reformatDate(
                        planInfo?.payment_information?.paused_at
                      )}{" "}
                    </span>
                    effective from{" "}
                    {planInfo?.payment_information?.paused_from !==
                      "waiting" && (
                      <span
                        style={{
                          color: "#000",
                        }}
                      >
                        {reformatDate(
                          planInfo?.payment_information?.paused_from
                        )}
                        <button
                          onClick={() => {
                            resumeSubscription({
                              pause: false,
                            });
                          }}
                          className="resume-subscription-btn"
                        >
                          Resume Subscription
                        </button>
                      </span>
                    )}
                  </p>
                )
              )}
            </div>
          </div>
          <div className="rightsubcontent">
            <StyledButton
              onClick={() => {
                setModal({
                  upgradeModal: !modal.upgradeModal,
                });
              }}
              backgroundColor="#33CDD1"
              textColor="#fff"
              fontSize="16px"
              fontWeight="600"
              width="180px"
              hoverBackgroundColor="#3ab7bb"
            >
              Upgrade Plan
            </StyledButton>
            <StyledButton
              onClick={() => {
                setModal({
                  cancelModal: !modal.cancelModal,
                });
              }}
              backgroundColor="#fff"
              textColor="#52575F"
              fontSize="16px"
              fontWeight="600"
              width="180px"
              border="1px solid #dfe5f3"
              hoverBorder="1px solid #C2CCE1"
            >
              Cancel Subscription
            </StyledButton>
          </div>
        </div>
      </div>
      <CardContainer
        cardInfo={planInfo?.payment_information}
        updateURL={planInfo?.update_url}
      />
    </div>
  );
};

export default CurrentPlan;
