import moment from "moment";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CsvDownloader from "react-csv-downloader";
import { FaCopy } from "react-icons/fa";
import Select from "react-select";
import * as XLSX from "xlsx";

import ReactDateRangePicker from "../../utils/ReactDateRangePicker";

import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Button as RSButton,
  Row,
} from "reactstrap";
import noDataImage from "../../assets/no_data.svg";
import { validateEmail } from "../../utils/helpers";
import DropdownStyle from "../../utils/tableDropdownStyle";
import Loader from "../Common/MyLoader";

const fbCsvColumns = [
  {
    id: "first_name",
    displayName: "first_name",
  },
  {
    id: "last_name",
    displayName: "last_name",
  },
  {
    id: "email",
    displayName: "email",
  },
];

const csvcolumns = [
  {
    id: "member_name",
    displayName: "Full Name",
  },
  {
    id: "question1",
    displayName: " Question 1",
  },
  {
    id: "answer1",
    displayName: "Answer 1",
  },
  {
    id: "question2",
    displayName: "Question 2",
  },
  {
    id: "answer2",
    displayName: "Answer 2",
  },
  {
    id: "question3",
    displayName: "Question 3",
  },
  {
    id: "answer3",
    displayName: "Answer 3",
  },
];

const renderShowsTotal = (start, to, total) => {
  return (
    <p style={{ color: "blue" }}>
      From {start} to {to}, totals is {total}&nbsp;&nbsp;(its a customize text)
    </p>
  );
};

const tableoptions = {
  paginationSize: 5,
  pageStartIndex: 1,
  alwaysShowAllBtns: true,
  withFirstAndLast: false,
  prePageText: "<",
  nextPageText: ">",
  showTotal: true,
  paginationShowsTotal: renderShowsTotal,
  sizePerPageList: [30, 50, 100],
  hidePageListOnlyOnePage: true,
};

function Table(props) {
  const [tablevalue, setTableValue] = useState([]);
  const [fbCustomAudience, setFbCustomAudience] = useState([]);
  const [sheetData, setSheetData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    const { getGroupList, getTableData, selectedGroup } = props;
    getGroupList();
    if (selectedGroup) {
      let payload = {
        groupId: selectedGroup.id,
      };
      getTableData(payload);
    } else if (!selectedGroup && localStorage.getItem("selected_group")) {
      let payload = {
        groupId: JSON.parse(localStorage.getItem("selected_group")).id,
      };
      getTableData(payload);
    }
    updateSize();
  }, [props.selectedGroup]);

  useEffect(() => {
    const {
      tableData,
      getGroupList,
      getTableData,
      selectedGroup,
      redirect,
    } = props;
    if (redirect) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_first_name");
      localStorage.removeItem("user_last_name");
      localStorage.removeItem("user_emails");
      localStorage.removeItem("user_default_email");
      localStorage.removeItem("selected_group");
      navigate("/login");
    }
 
    if (tableData) {
      if (tableData.length > 0) {
        const fbCustomAudienceData = [];
        const tableValueData = [];
        const sheetData = [];

        tableData.forEach((item, i) => {
          const first_name = item.member_name.split(" ")[0];
          const last_name = item.member_name.split(" ")[1];
          let isEmail;
          let email = "";
          if (item.answer1 && validateEmail(item.answer1)) {
            isEmail = 1;
          } else if (item.answer2 && validateEmail(item.answer2)) {
            isEmail = 2;
          } else if (item.answer3 && validateEmail(item.answer3)) {
            isEmail = 3;
          }
          if (isEmail) {
            email =
              isEmail === 1
                ? item.answer1
                : isEmail === 2
                ? item.answer2
                : item.answer3;
            fbCustomAudienceData.push({
              first_name,
              last_name,
              email,
            });
          }
          tableValueData.push({
            id: i + 1,
            email,
            ...item,
          });

          const singleSheetData = {};

          singleSheetData["Full Name"] = item.member_name;
          singleSheetData["First Name"] = first_name;
          singleSheetData["Last Name"] = last_name;
          singleSheetData["Email"] = email;
          singleSheetData["Question 1"] = item.question1;
          singleSheetData["Answer 1"] = item.answer1;
          singleSheetData["Question 2"] = item.question2;
          singleSheetData["Answer 2"] = item.answer2;
          singleSheetData["Question 3"] = item.question3;
          singleSheetData["Answer 3"] = item.answer3;

          sheetData.push(singleSheetData);
        });
        setTableValue(tableValueData);
        setFbCustomAudience(fbCustomAudienceData);
        setSheetData(sheetData);
      } else if (tableData.length === 0) {
        setTableValue([]);
        setFbCustomAudience([]);
        setSheetData([]);
      }
    }
  }, [props.tableData, props.selectedGroup]);

  const getTodaysData = () => {
    const { getTableDataByDate, selectedGroup } = props;
    let date = moment(new Date()).format("YYYY-MM-DD");
    setStartDate(null);
    setEndDate(null);
    if (selectedGroup) {
      let payload = {
        groupId: selectedGroup.id,
        fromDate: date,
        toDate: date,
      };
      getTableDataByDate(payload);
    } else if (!selectedGroup && localStorage.getItem("selected_group")) {
      let payload = {
        groupId: JSON.parse(localStorage.getItem("selected_group")).id,
        fromDate: date,
        toDate: date,
      };
      getTableDataByDate(payload);
    }
  };

  const changeDateRange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setCalendarIsOpen(true);
  };

  const dateRangeData = () => {
    const { getTableDataByDate, selectedGroup } = props;

    setCalendarIsOpen(false);
    if (selectedGroup) {
      let payload = {
        groupId: selectedGroup.id,
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
      };
      getTableDataByDate(payload);
    } else if (!selectedGroup && localStorage.getItem("selected_group")) {
      let payload = {
        groupId: JSON.parse(localStorage.getItem("selected_group")).id,
        fromDate: moment(startDate).format("YYYY-MM-DD"),
        toDate: moment(endDate).format("YYYY-MM-DD"),
      };
      getTableDataByDate(payload);
    }
  };

  const facebookProfile = (rowData) => {
    return (
      <a
        href={rowData.user_Profile}
        target="_blank"
        rel="noopener noreferrer">
        {rowData.user_Profile.replace(/^https?:\/\//, "")}
      </a>
    );
  };

  const exportToSheet = (data) => {
    if (data && data.length > 0) {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "GroupbossData");
      XLSX.writeFile(wb, `groupboss_data.xlsx`);
    }
  };

  const handleChangeGroup = (e) => {
    let selectedGroupData = {
      id: e.value,
      name: e.label,
    };
    localStorage.setItem("selected_group", JSON.stringify(selectedGroupData));
    props.selectGroup(e);
  };

  const updateSize = () => {
    setWindowWidth(window.innerWidth);
  };

  const { groupList, selectedGroup, isLoading } = props;

  let groupListOptions = [];
  if (groupList && groupList.length > 0) {
    groupList.forEach((group) => {
      groupListOptions.push({
        label: group.name,
        value: group.group_id,
      });
    });
  }

  const member_name = (cell, row) => {
    return (
      <div>
        {row.member_name}
        <CopyToClipboard
          text={row.profile_url}
          onCopy={() => setCopied(true)}>
          <p className="copyUrl">
            <FaCopy /> &nbsp;Copy facebook profile link
          </p>
        </CopyToClipboard>
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
    },
    {
      dataField: "member_name",
      text: "Full Name",
      formatter: member_name,
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "question1",
      text: "Question 1",
    },
    {
      dataField: "answer1",
      text: "Answer 1",
    },
    {
      dataField: "question2",
      text: "Question 2",
    },
    {
      dataField: "answer2",
      text: "Answer 2",
    },
    {
      dataField: "question3",
      text: "Question 3",
    },
    {
      dataField: "answer3",
      text: "Answer 3",
    },
  ];


  return (
    <div style={{ padding: "30px" }}>
      <Loader open={isLoading} />
      <Row className="groupsTimeRangeContainer">
        <div 
        style={{
          padding: 0
        }}
        >
          <p>Groups</p>
          <Select
            isSearchable
            placeholder="Search by group name"
            value={
              selectedGroup && selectedGroup.id !== ""
                ? {
                    label: selectedGroup.name,
                    value: selectedGroup.id,
                  }
                : localStorage.getItem("selected_group")
                ? {
                    label: JSON.parse(localStorage.getItem("selected_group"))
                      .name,
                    value: JSON.parse(localStorage.getItem("selected_group"))
                      .id,
                  }
                : null
            }
            options={groupListOptions}
            onChange={(e) => handleChangeGroup(e)}
            styles={DropdownStyle(windowWidth)}
          />
        </div>
        <div 
        style={{
          padding: 0
        }}
        className="todayAndTimeRangeContainer">
          <div className="todayButtonDiv">
            <Button
              label="Today"
              className="p-button-secondary"
              onClick={() => getTodaysData()}
              style={{
                backgroundColor: "#51555E",
                width: "100%",
                minWidth: "117px",
                }}
            />
          </div>
          <div 
          className="selectTimeRange">
            <p
              className="p-inputgroup-addon"
              style={{ 
                display: "flex",
                justifyContent: "start"
               }}>
              Select Time Range
            </p>
            <ReactDateRangePicker
              startDate={startDate}
              startDateId="custom-date-range-start-date"
              endDate={endDate}
              endDateId="custom-date-range-end-date"
              onDatesChange={changeDateRange}
              dateDisplayFormat="DD/MM/YYYY"
              onDateSelect={dateRangeData}
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </Row>
      <Row style={{ margin: "30px 0" }}>
        <Col md={12}>
          <Card className="dashboard-card">
            {tablevalue && tablevalue.length > 0 ? (
              <>
                <CardHeader className="dashboard-card-header">
                  <Row className="tableHeaderContainer">
                    <div className="groupName">
                      {selectedGroup ? selectedGroup.name : JSON.parse(localStorage.getItem("selected_group"))
                      .name ? JSON.parse(localStorage.getItem("selected_group"))
                      .name : ""}
                    </div>
                    <div className="CSVButtons">
                      <CsvDownloader
                        filename="FB Custom Audience"
                        separator=","
                        columns={fbCsvColumns}
                        datas={fbCustomAudience}>
                        <RSButton className="fb-audience-button">
                          Create FB Custom and Lookalike Audience
                        </RSButton>
                      </CsvDownloader>
                      <CsvDownloader
                        filename="Groupdata"
                        separator=","
                        columns={csvcolumns}
                        datas={tablevalue}>
                        <Button
                          label="Download as CSV"
                          className="p-button-secondary "
                          style={{
                            backgroundColor: "#33cdd1",
                            width: "100%",
                          }}
                        />
                      </CsvDownloader>
                      <Button
                        label="Download as Excel"
                        className="p-button-secondary"
                        style={{
                          backgroundColor: "#34a852",
                        }}
                        onClick={() => exportToSheet(sheetData)}
                      />
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <BootstrapTable
                    keyField="id"
                    data={tablevalue}
                    columns={columns}
                    pagination={paginationFactory(tableoptions)}
                    wrapperClasses="table-responsive"
                  />
                </CardBody>
              </>
            ) : (
              <>
                <CardHeader className="dashboard-card-header"></CardHeader>
                <CardBody style={{ padding: "10vh 25px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <img
                      src={noDataImage}
                      alt=""
                      style={{ width: "200px" }}
                    />
                    <p
                      style={{
                        fontSize: "20px",
                        marginTop: "20px",
                        color: "#909497",
                      }}>
                      Sorry there is no data available for this group
                    </p>
                  </div>
                </CardBody>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Table;
