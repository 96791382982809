//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi, noHeaderApi } from "../../utils/axiosApi";

//Constants
import {
  GET_SUBSCRIPTION_PLAN,
  GET_SUBSCRIPTION_PLAN_SUCCESS,
  GET_SUBSCRIPTION_PLAN_FAILURE,
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
  UPDATE_SUBSCRIPTION_PLAN_FAILURE,
  UPDATE_SUBSCRIPTION_PLAN,
  PAUSE_SUBSCRIPTION_SUCCESS,
  PAUSE_SUBSCRIPTION_FAILURE,
  PAUSE_SUBSCRIPTION,
} from "../../utils/constants";

export function* getSubscriptionPlanRequest({ payload }) {
  try {
    let url = `subscription/get-subscription-details/`;

    if (payload) {
      url = `subscription/get-subscription-details/?refetch=true`;
    }
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_SUBSCRIPTION_PLAN_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_SUBSCRIPTION_PLAN_FAILURE,
      payload: err,
    });
  }
}

export function* upgradeSubscriptionPlanRequest({ payload }) {
  try {
    let url = `subscription/upgrade-subscription/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_SUBSCRIPTION_PLAN_FAILURE,
      payload: err,
    });
  }
}

export function* handlePauseSubscriptionRequest({ payload }) {
  try {
    let url = `subscription/pause-subscription/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: PAUSE_SUBSCRIPTION_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: PAUSE_SUBSCRIPTION_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_SUBSCRIPTION_PLAN, getSubscriptionPlanRequest),
    takeLatest(UPDATE_SUBSCRIPTION_PLAN, upgradeSubscriptionPlanRequest),
    takeLatest(PAUSE_SUBSCRIPTION, handlePauseSubscriptionRequest),
  ]);
}
