import { CHANGE_PASSWORD_REQUEST } from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function changePassword(value) {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: value
  };
}

export const actions = {
  changePassword
};
