import { FORGOT_PASSWORD_REQUEST } from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function forgotPassword(value) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: value
  };
}

export const actions = {
  forgotPassword
};
