import React from "react";

const PossibleReasonBox = ({color, text}) => {
  return (
    <span className="possible-reason-box">
      <div
        style={{
          height: "10px",
          width: "10px",
          backgroundColor: color,
          borderRadius: "50%",
        }}
      />
      <span>{text}</span>
    </span>
  );
};

export default PossibleReasonBox;
