import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { password_validate } from "../../utils/helpers";

import Loader from "../Common/MyLoader";

import { useNavigate } from "react-router-dom";
import hidePasswordIcon from "../../assets/hide.svg";
import showPasswordIcon from "../../assets/view.svg";

function Change(props) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [saveErrors, setSaveErrors] = useState({});

  const { isLoading, passwordChanged, changePassword } = props;

  const navigate = useNavigate();

  const onTextChange = (e) => {
    const { name, value } = e.target;
    setSaveErrors({});
    if (name === "currentPassword") setCurrentPassword(value);
    else if (name === "newPassword") setNewPassword(value);
    else if (name === "confirmNewPassword") setConfirmNewPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleSaveError()) {
      const payload = {
        old_password: currentPassword,
        new_password: newPassword,
      };
      changePassword(payload);
    }
  };

  const handleSaveError = () => {
    const errors = {};
    let formIsValid = true;

    if (currentPassword === "") {
      formIsValid = false;
      errors["currentPassword"] = "Password can't be empty";
    }
    if (newPassword === "") {
      formIsValid = false;
      errors["newPassword"] = "New Password can't be empty";
    }
    if (
      currentPassword !== "" &&
      newPassword !== "" &&
      newPassword !== confirmNewPassword &&
      password_validate(newPassword) === true
    ) {
      formIsValid = false;
      errors["confirmNewPassword"] = "Passwords do not match";
    }
    if (newPassword !== "" && password_validate(newPassword) === false) {
      formIsValid = false;
      errors["newPassword"] =
        "Passwords must contain a minimum of eight characters, at least one uppercase letter, one lowercase letter, and a number";
    }

    setSaveErrors(errors);
    return formIsValid;
  };

  const toggleShow = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  useEffect(() => {
    if (passwordChanged) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_first_name");
      localStorage.removeItem("user_last_name");
      localStorage.removeItem("user_emails");
      localStorage.removeItem("user_default_email");
      localStorage.removeItem("selected_group");
      navigate("/login");
    }
  }, [passwordChanged, navigate]);

  return (
    <div className="login-page">
      <Loader open={isLoading} />
      <Row className="align-items-center">
        <Col md={12}>
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20vh",
            }}>
            <Col
              md={5}
              lg={4}
              xl={3}
              sm={9}>
              <div className="signupForm">
                <h3
                  style={{ textAlign: "center" }}
                  className="signin">
                  Change Password
                </h3>
                <br />
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <div id="input_containerp">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        name="currentPassword"
                        onChange={onTextChange}
                        id="inputP1"
                        className="form-control showPass"
                        placeholder="Enter Current Password"
                        value={currentPassword}
                      />
                      <img
                        src={
                          isPasswordVisible
                            ? hidePasswordIcon
                            : showPasswordIcon
                        }
                        alt="show password"
                        style={{ height: "27px", width: "27px" }}
                        id="input_img"
                        title="Show Password"
                        onClick={toggleShow}
                      />
                    </div>
                    <span className="error-message">
                      {saveErrors["currentPassword"]}
                    </span>
                  </div>
                  <div className="form-group">
                    <div id="input_containerp">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        name="newPassword"
                        onChange={onTextChange}
                        id="inputPasswordMatch1"
                        className="form-control showPass"
                        placeholder="Enter New Password"
                        value={newPassword}
                      />
                      <img
                        src={
                          isPasswordVisible
                            ? hidePasswordIcon
                            : showPasswordIcon
                        }
                        alt="show password"
                        style={{ height: "27px", width: "27px" }}
                        id="input_img"
                        title="Show Password"
                        onClick={toggleShow}
                      />
                    </div>
                    <span className="error-message">
                      {saveErrors["newPassword"]}
                    </span>
                  </div>
                  <div className="form-group">
                    <div id="input_containerp">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        name="confirmNewPassword"
                        onChange={onTextChange}
                        id="inputPasswordMatch2"
                        className="form-control showPass"
                        placeholder="Confirm New Password"
                        value={confirmNewPassword}
                      />
                      <img
                        src={
                          isPasswordVisible
                            ? hidePasswordIcon
                            : showPasswordIcon
                        }
                        alt="show password"
                        style={{ height: "27px", width: "27px" }}
                        id="input_img"
                        title="Show Password"
                        onClick={toggleShow}
                      />
                    </div>
                    <span className="error-message">
                      {saveErrors["confirmNewPassword"]}
                    </span>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block groupboss">
                      Change Password
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Change;
