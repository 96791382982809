import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";

import crossIcon from "../../assets/img/icons/cross_black.svg";
import { Package } from "./Package";
import { paddleCheckout } from "./Paddle";

const packages = [
  {
    name: "Groupboss Quarterly",
    price: "45",
    priceDesc: "Recurring Plan. Cancel Anytime",
    primaryPackageColor: "#FFB946",
    features: [
      "Add up to 3 Facebook groups",
      "Access to Googlesheet integration",
      "Unlimited auto-responders",
      "Lifetime update to software",
      "Option to export data both in CSV and Excel format from the dashboard.",
      "Create FB custom and lookalike audience",
      "VIP Facebook group for users",
      "Instant chat support",
      "14 days refund guarantee",
      "Exclusive support through (zoom/skype)",
    ],
    id: parseInt(process.env.REACT_APP_AVAILABLE_PLAN_3),
    isCurrent: false,
  },
  {
    name: "Groupboss Yearly",
    price: "99",
    priceDesc: "Recurring Plan. Cancel Anytime",
    primaryPackageColor: "#00D4FF",
    features: [
      "Add up to 3 Facebook groups",
      "Access to Googlesheet integration",
      "Unlimited auto-responders",
      "Lifetime update to software",
      "Option to export data both in CSV and Excel format from the dashboard.",
      "Create FB custom and lookalike audience",
      "VIP Facebook group for users",
      "Instant chat support",
      "14 days refund guarantee",
      "Exclusive support through (zoom/skype)",
    ],
    id: parseInt(process.env.REACT_APP_AVAILABLE_PLAN_1),
    isCurrent: false,
  },
  {
    name: "Groupboss Yearly (Mega Plan)",
    price: "189",
    priceDesc: "Recurring Plan. Cancel Anytime",
    primaryPackageColor: "#635BFF",
    features: [
      "Add up to 20 Facebook groups",
      "Access to Googlesheet integration",
      "Unlimited auto-responders",
      "Lifetime update to software",
      "Option to export data both in CSV and Excel format from the dashboard.",
      "Create FB custom and lookalike audience",
      "VIP Facebook group for users",
      "Instant chat support",
      "14 days refund guarantee",
      "Exclusive support through (zoom/skype)",
    ],
    id: parseInt(process.env.REACT_APP_AVAILABLE_PLAN_2),
    isCurrent: false,
  },
];

function UpgradePlanModal({
  modal,
  setModal,
  planStatus,
  planId,
  userMail,
  initiatePlanUpgrade,
}) {
  const openCheckout = (id, name) => {
    paddleCheckout(id, name, userMail);
  };

  useEffect(() => {
    packages.forEach((item) => {
      if (parseInt(item.id) === parseInt(planId) && planStatus !== "deleted") {
        item.isCurrent = true;
      } else {
        item.isCurrent = false;
      }
    });
  }, [planStatus, planId]);

  const toggle = () =>
    setModal({
      upgradeModal: !modal.upgradeModal,
    });


  return (

    <div>
      <Modal
        isOpen={modal.upgradeModal}
        toggle={toggle}
        backdrop={true}
        keyboard={true}
        centered={true}
        size="xl">
        <img
          src={crossIcon}
          alt="cross"
          className="cross-icon"
          onClick={toggle}
        />

        <ModalBody>
          <div className="upgrade-modal-body">
            <p
              style={{
                fontSize: "20px",
              }}>
              Select plan to proceed
            </p>
            <p
              style={{
                color: "#617186",
              }}>
              Try Risk Free with No Questions Asked Money Back Guarantee.
            </p>
            <div className="upgrade-package">
              {packages.map((item, index) => (
                <Package
                  key={index}
                  data={item}
                  onclick={() => {
                    planStatus === "deleted" || !planStatus
                      ? openCheckout(item.id, item.name)
                      : initiatePlanUpgrade({
                          plan_id: item.id,
                        });
                    toggle();
                  }}
                />
              ))}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default UpgradePlanModal;
