import React from "react";
// import Loader from "react-loader-spinner";
import { ThreeDots } from  'react-loader-spinner'

import { Modal, ModalBody } from "reactstrap";

const MyLoader = (props) => {
  return (
    <Modal className="custom-loader" isOpen={props.open}>
      <ModalBody>
        <ThreeDots type="ThreeDots" color="#33CDD1" height={50} width={80} />
      </ModalBody>
    </Modal>
  );
}

export default MyLoader;