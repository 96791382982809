import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useDashboardRedirector = () => {
  const navigate = useNavigate();
  //   if show_board is false then redirect to dashboard
  useEffect(() => {
    let firstTimeUser = localStorage.getItem("show_board");
    if (firstTimeUser === "false") {
      navigate("/user/dashboard");
    }
  }, []);
};
