import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import { password_validate } from "../../utils/helpers";

import Loader from "../Common/MyLoader";

import { useNavigate } from "react-router-dom";
import cancelIcon from "../../assets/cancel.svg";
import editIcon from "../../assets/edit.svg";
import hidePasswordIcon from "../../assets/hide.svg";
import plusIcon from "../../assets/plus.svg";
import tickIcon from "../../assets/tick.svg";
import showPasswordIcon from "../../assets/view.svg";

const AccountSettings = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    isPasswordVisible: false,
    saveErrors: {},
    editIcon: false,
    tickDiv: "",
    email: "",
    correctEmail: false,
    addEmail: false,
    cancel2: false,
  });

  const editHandler = () => {
    setState((prevState) => ({
      ...prevState,
      editIcon: !prevState.editIcon,
    }));
  };

  const tickHandler = () => {
    if (state.correctEmail) {
      const payload = {
        email: state.email,
      };
      props.editRequest(payload);
      setState({ ...state, tickDiv: "correct" });
    } else {
      setState({ ...state, tickDiv: "false" });
    }
  };

  const cancelHandler = () => {
    setState((prevState) => ({
      ...prevState,
      cancelDiv: !prevState.cancelDiv,
      editIcon: !prevState.editIcon,
      tickDiv: "",
    }));
  };

  const validEmail = (e) => {
    const email = e.target.value;
    setState({
      ...state,
      email,
      correctEmail: /\S+@\S+\.\S+/.test(email),
    });
  };

  const handleAddEmail = () => {
    setState((prevState) => ({
      ...prevState,
      addEmail: !prevState.addEmail,
    }));
  };

  useEffect(() => {
    const { passwordChanged } = props;
    if (passwordChanged) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_first_name");
      localStorage.removeItem("user_last_name");
      localStorage.removeItem("user_emails");
      localStorage.removeItem("user_default_email");
      localStorage.removeItem("selected_group");
      navigate("/login");
    }
  }, [props.passwordChanged]);

  const onTextChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      saveErrors: {},
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { currentPassword, newPassword } = state;
    const { changePassword } = props;
    if (handleSaveError()) {
      const payload = {
        old_password: currentPassword,
        new_password: newPassword,
      };
      changePassword(payload);
    }
  };

  const handleSaveError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (state.currentPassword === "") {
      formIsValid = false;
      saveErrors["currentPassword"] = "Password can't be empty";
    }
    if (state.newPassword === "") {
      formIsValid = false;
      saveErrors["newPassword"] = "New Password can't be empty";
    }
    if (
      state.currentPassword !== "" &&
      state.newPassword !== "" &&
      state.newPassword !== state.confirmNewPassword &&
      password_validate(state.newPassword) === true
    ) {
      formIsValid = false;
      saveErrors["confirmNewPassword"] = "Passwords do not match";
    }
    if (
      state.newPassword !== "" &&
      password_validate(state.newPassword) === false
    ) {
      formIsValid = false;
      saveErrors["newPassword"] =
        "Passwords must contain a minimum of eight characters, at least one uppercase letter, one lowercase letter, and a number";
    }

    setState({ ...state, saveErrors: saveErrors });
    return formIsValid;
  };

  const toggleShow = () => {
    setState((prevState) => ({
      ...prevState,
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  const {
    currentPassword,
    newPassword,
    confirmNewPassword,
    isPasswordVisible,
  } = state;

  return (
    <div style={{ padding: "61px 60px 80px 60px" }}>
      <Loader open={props.isLoading} />
      <div
        style={{
          backgroundColor: "#FFFFFF",
          fontFamily: "nova",
          color: "#33475B",
          opacity: "1",
          borderRadius: "19px",
        }}>
        <div style={{ paddingTop: "60px", paddingLeft: "60px" }}>
          <p style={{ fontWeight: "bold", fontSize: "24px", opacity: "1" }}>
            Account Settings
          </p>
        </div>
        <hr></hr>
        <div style={{ paddingLeft: "60px" }}>
          <p style={{ fontWeight: "bold", fontSize: "20px", opacity: ".6" }}>
            Email Address
          </p>
          <p style={{ fontWeight: "normal", fontSize: "18px", opacity: ".8" }}>
            Below is your email address you used before for all notifications
            and updates
          </p>
          <Row style={{ marginBottom: "5px" }}>
            <div
              style={{
                fontWeight: "normal",
                fontSize: "18px",
                opacity: ".8",
                marginLeft: "15px",
                paddingTop: "5px",
                marginTop: "5px",
              }}>
              Email
            </div>
            <div>
              {state.editIcon ? (
                <Row style={{ marginBottom: "20px" }}>
                  <input
                    id="emailInputId"
                    type="text"
                    style={{
                      marginLeft: "45px",
                      borderRadius: "6px",
                      opacity: "1",
                      border: "1px solid #33CDD1",
                      width: "283px",
                      height: "50px",
                      paddingLeft: "19px",
                    }}
                    onChange={(e) => validEmail(e)}
                    value={state.email}
                  />
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "6px",
                      border: "1px solid #CACEDD",
                      opacity: "1",
                      marginLeft: "10px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                    onClick={() => tickHandler()}>
                    <img
                      src={tickIcon}
                      alt="tick mark"
                    />{" "}
                    {/* ////////// tick Icon \\\\\\\\\\\\\\\\ */}
                  </div>
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "6px",
                      border: "1px solid #CACEDD",
                      opacity: "1",
                      marginLeft: "10px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                    onClick={() => cancelHandler()}>
                    <img
                      src={cancelIcon}
                      alt="cancel button"
                    />{" "}
                    {/* ////////// Cross Icon \\\\\\\\\\\\\\\\ */}
                  </div>
                </Row>
              ) : (
                <span>
                  <Row>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: "18px",
                        marginLeft: "65px",
                        opacity: "1",
                        paddingTop: "5px",
                        marginTop: "5px",
                      }}>
                      {localStorage.getItem("user_default_email")}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#E7ECEF",
                        borderRadius: "6px",
                        opacity: "1",
                        width: "36px",
                        height: "36px",
                        marginLeft: "26px",
                        textAlign: "center",
                        marginTop: "5px",
                        marginBottom: "5px",
                        paddingTop: "5px",
                      }}
                      onClick={() => editHandler()}
                      id="editDiv">
                      <img
                        src={editIcon}
                        alt="edit icon"
                        style={{
                          background: "transparent",
                          opacity: "1",
                          width: "12px",
                          height: "12px",
                        }}
                        title="edit icon"
                      />
                    </div>
                  </Row>
                </span>
              )}
            </div>
          </Row>
          {/* ////////////////code for tick action  \\\\\\\\\\\\\\\\\\\\\\\\\\\ */}
          <div>
            {state.tickDiv === "correct" ? (
              <p
                id="correctId"
                style={{
                  color: "#1EAC7C",
                  fontSize: "18px",
                  marginLeft: "76px",
                  marginBottom: "30px",
                  opacity: "1",
                }}>
                We have sent an email to '<span>{state.email}</span>', please
                check your email to verify!
              </p>
            ) : state.tickDiv === "false" ? (
              <p
                id="incorrectId"
                style={{
                  color: "#EE0B1B",
                  fontSize: "18px",
                  marginLeft: "76px",
                  marginBottom: "30px",
                  opacity: "1",
                }}>
                Maybe you entered an incorrect email, please check!
              </p>
            ) : (
              <p></p>
            )}
          </div>
          {/* ////////////////code for cross action \\\\\\\\\\\\\\\\\\\\\\\\\\\ */}
          <div>{state.cancelDiv && <div></div>}</div>
          {/* //////////////// code for edit Icon \\\\\\\\\\\\\\\\\\\\\\\\\\\ */}
          <div>
            {state.editIcon ? (
              <span>
                <span></span>
                <span></span>
              </span>
            ) : state.addEmail === false ? (
              <Row>
                <div
                  style={{
                    marginLeft: "108px",
                    marginBottom: "10px",
                    color: "#33CDD1",
                    fontWeight: "normal",
                    opacity: "1",
                  }}
                  onClick={() => handleAddEmail()}>
                  <img
                    src={plusIcon}
                    alt="plus icon"
                    style={{ height: "14px", width: "14px" }}
                    title="plus icon"
                  />
                  <span style={{ marginLeft: "10px", fontSize: "16px" }}>
                    Add email address
                  </span>
                </div>
              </Row>
            ) : state.cancel2 ? (
              <p></p>
            ) : (
              <div>
                <Row style={{ marginBottom: "20px" }}>
                  <input
                    id="emailInputId"
                    type="text"
                    style={{
                      marginLeft: "85px",
                      borderRadius: "6px",
                      opacity: "1",
                      border: "1px solid #33CDD1",
                      width: "283px",
                      height: "50px",
                      paddingLeft: "19px",
                    }}
                    onChange={(e) => validEmail(e)}
                    value={state.email}
                  />
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "6px",
                      border: "1px solid #CACEDD",
                      opacity: "1",
                      marginLeft: "10px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                    onClick={() => handleAddEmail()}>
                    <img
                      src={cancelIcon}
                      alt="cancel button"
                    />{" "}
                    {/* //////////2nd Cross Icon \\\\\\\\\\\\\\\\ */}
                  </div>
                </Row>
                <div>
                  <button
                    type="button"
                    style={{
                      width: "283px",
                      height: "50px",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "16px",
                      color: "#FFFFFF",
                      opacity: "1",
                      backgroundColor: "#33CDD1",
                      borderRadius: "6px",
                      marginLeft: "70px",
                      border: "1px solid #33CDD1",
                    }}>
                    Add Email
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr></hr>

        <div>
          <div style={{ paddingLeft: "60px", marginTop: "30px" }}>
            <p style={{ fontWeight: "bold", fontSize: "20px", opacity: ".6" }}>
              Change Password
            </p>
          </div>
          <div
            style={{
              paddingLeft: "60px",
              marginTop: "30px",
              marginBottom: "30px",
            }}>
            <p
              style={{
                fontWeight: "normal",
                fontSize: "18px",
                opacity: ".8",
              }}>
              Below is your email address you used before for all notifications
              and updates
            </p>
          </div>
          <div style={{ paddingBottom: "91px", paddingLeft: "45px" }}>
            <Col
              md={5}
              lg={4}
              xl={3}
              sm={9}>
              <Form onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group">
                  <div id="input_containerp">
                    <input
                      type={state.isPasswordVisible ? "text" : "password"}
                      name="currentPassword"
                      onChange={(event) => onTextChange(event)}
                      id="inputP1"
                      className="form-control showPass"
                      placeholder="Enter Current Password"
                      value={currentPassword}
                    />
                    <img
                      src={
                        state.isPasswordVisible
                          ? hidePasswordIcon
                          : showPasswordIcon
                      }
                      alt="show password"
                      style={{ height: "27px", width: "27px" }}
                      id="input_img"
                      title="Show Password"
                      onClick={toggleShow}
                    />
                  </div>
                  <span className="error-message">
                    {state.saveErrors["currentPassword"]}
                  </span>
                </div>
                <div className="form-group">
                  <div id="input_containerp">
                    <input
                      type={state.isPasswordVisible ? "text" : "password"}
                      name="newPassword"
                      onChange={(event) => onTextChange(event)}
                      id="inputPasswordMatch1"
                      className="form-control showPass"
                      placeholder="Enter New Password"
                      value={newPassword}
                    />
                    <img
                      src={
                        state.isPasswordVisible
                          ? hidePasswordIcon
                          : showPasswordIcon
                      }
                      alt="show password"
                      style={{ height: "27px", width: "27px" }}
                      id="input_img"
                      title="Show Password"
                      onClick={toggleShow}
                    />
                  </div>
                  <span className="error-message">
                    {state.saveErrors["newPassword"]}
                  </span>
                </div>
                <div className="form-group">
                  <div id="input_containerp">
                    <input
                      type={state.isPasswordVisible ? "text" : "password"}
                      name="confirmNewPassword"
                      onChange={(event) => onTextChange(event)}
                      id="inputPasswordMatch2"
                      className="form-control showPass"
                      placeholder="Confirm New Password"
                      value={confirmNewPassword}
                    />
                    <img
                      src={
                        state.isPasswordVisible
                          ? hidePasswordIcon
                          : showPasswordIcon
                      }
                      alt="show password"
                      style={{ height: "27px", width: "27px" }}
                      id="input_img"
                      title="Show Password"
                      onClick={toggleShow}
                    />
                  </div>
                  <span className="error-message">
                    {state.saveErrors["confirmNewPassword"]}
                  </span>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block groupboss">
                    Change Password
                  </button>
                </div>
              </Form>
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
