//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  REQUEST_TABLE_DATA,
  REQUEST_TABLE_DATA_SUCCESS,
  REQUEST_TABLE_DATA_FAILURE,
  REQUEST_TABLE_DATA_BY_DATE,
  REQUEST_TABLE_DATA_BY_DATE_SUCCESS,
  REQUEST_TABLE_DATA_BY_DATE_FAILURE,
  SELECT_GROUP,
  SELECT_GROUP_SUCCESS,
  SELECT_GROUP_FAILURE
} from "../../utils/constants";

export function* tableDataRequest({ payload }) {
  try {
    const url = `questions/${payload.groupId}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: REQUEST_TABLE_DATA_SUCCESS,
      payload: { data: response }
    });
  } catch (err) {
    yield put({
      type: REQUEST_TABLE_DATA_FAILURE,
      payload: err
    });
  }
}

export function* tableDataByDateRequest({ payload }) {
  try {
    const url = `questions/${payload.groupId}?fdate=${payload.fromDate}&tdate=${payload.toDate}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: REQUEST_TABLE_DATA_BY_DATE_SUCCESS,
      payload: { data: response }
    });
  } catch (err) {
    yield put({
      type: REQUEST_TABLE_DATA_BY_DATE_FAILURE,
      payload: err
    });
  }
}

export function* selectGroupRequest({ payload }) {
  try {
    let selectedGroup = {
      id: payload.value,
      name: payload.label
    };
    yield put({
      type: SELECT_GROUP_SUCCESS,
      payload: selectedGroup
    });
  } catch (err) {
    yield put({
      type: SELECT_GROUP_FAILURE,
      payload: err
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(REQUEST_TABLE_DATA, tableDataRequest),
    takeLatest(REQUEST_TABLE_DATA_BY_DATE, tableDataByDateRequest),
    takeLatest(SELECT_GROUP, selectGroupRequest)
  ]);
}
