import React from "react";
import planPaused from "../../assets/img/subscription-paused.svg";
import { StyledButton } from "../../styledComponents/buttons";
import { CapitalizedH3 } from "../../styledComponents/common";
import { getCurrencySymbol, getPlanName, getPlanType, reformatDate } from "../../utils/helpers";
import CardContainer from "./CardContainer";
import PossibleReasonBox from "./PossibleReasonBox";

const possibleReasons = [
  {
    color: "#1f1f1f",
    text: "You've chosen to take a break.",
  },
  {
    color: "#49CBB7",
    text: "Card is Expired",
  },
  {
    color: "#00D4FF",
    text: "Insufficient balance on your card.",
  },
  {
    color: "#F8A4BB",
    text: "Bank declined the payment",
  },
];
const PausedPlan = ({ planInfo, manageSubscription }) => {
  return (
    <div className="subscontent">
      <div
        style={{ padding: "2.5rem 2.5rem 0rem 2.5rem" }}
        className="innerTop">
        <p className="subheader">Current Plan</p>
        <div className="subs-rate-parent">
          <div className="paused-subs-rate-container">
              <CapitalizedH3>{getPlanName(planInfo?.paddle_plan_id)}</CapitalizedH3>
              <span className="subs-rate">{getCurrencySymbol(planInfo?.currency) + planInfo?.unit_price + getPlanType(planInfo?.paddle_plan_id) }</span>
            </div>
          <StyledButton
            onClick={() => {
              manageSubscription({
                pause: false,
              });
            }}
            backgroundColor="#33CDD1"
            textColor="#fff"
            fontSize="16px"
            fontWeight="600"
            width="180px"
            hoverBackgroundColor="#3ab7bb">
            Activate Plan
          </StyledButton>
        </div>
        <div className="subcenter">
          <div className="paused-plan-content">
            <div
              style={{
                margin: "20px 0",
                fontSize: "16px",
              }}>
              <p
                style={{
                  color: "#52575f",
                }}>
                Status
              </p>
              <span>
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "10px",
                  }}
                  src={planPaused}
                  alt="plan paused"
                  width="100%"
                />
                Subscription Paused
              </span>
              <div className="possible-reason">
                <p>Possible Reason of subscription paused</p>
                <div className="content">
                  {possibleReasons.map((reason, index) => (
                    <PossibleReasonBox
                      key={index}
                      color={reason.color}
                      text={reason.text}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div>
              <p style={{ color: "#52575f" }}>
                Member Since{" "}
                <span style={{ color: "#000" }}>
                  {reformatDate(planInfo?.created_at)}
                </span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <CardContainer
        cardInfo={planInfo?.payment_information}
        updateURL={planInfo?.update_url}
      />
    </div>
  );
};

export default PausedPlan;
