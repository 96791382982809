import {
  GET_SUBSCRIPTION_PLAN,
  PAUSE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_PLAN,
} from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getSubscriptionPlan(value) {
  return {
    type: GET_SUBSCRIPTION_PLAN,
    payload: value,
  };
}

export const upgradePlan = (data) => {
  return {
    type: UPDATE_SUBSCRIPTION_PLAN,
    payload: data,
  };
};

export const pauseSubscription = (data) => {
  return {
    type: PAUSE_SUBSCRIPTION,
    payload: data,
  };
};

export const actions = {
  getSubscriptionPlan,
  upgradePlan,
  pauseSubscription,
};
