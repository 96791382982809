import { toast } from "react-toastify";

export const createNotification = (type, message, timeout, subText) => {
  switch (type) {
    case "info":
      toast.info(message, {
        autoClose: { timeout }
      });
      break;
    case "success":
      toast.success(message, {
        autoClose: { timeout }
      });
      break;
    case "warning":
      toast.warn(message, {
        autoClose: { timeout }
      });
      break;
    case "error":
      toast.error(message, {
        autoClose: { timeout }
      });
      break;
    default:
      toast(message, {
        autoClose: 3000
      });
      break;
  }
};
