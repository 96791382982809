const DropdownStyles = (width) => {
  return {
    control: (styles, state) => {
      return {
        ...styles,
        borderRadius: "6px",
        marginLeft: width < 768 ? "0px" : "15px",
        height: "43px",
        width: width === true ? "310px" : "94%",
        paddingLeft: "5px",
        background: "#fff",
        boxShadow: state.isFocused ? "0 0 0 1px #C1CCD3" : "0",
        border: state.isFocused ? "1px solid #C1CCD3" : "none",
        "&:hover": {
          border: state.isFocused ? "1px solid #C1CCD3" : "none",
          boxShadow: state.isFocused ? "0 0 0 1px #C1CCD3" : "0",
        },
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        background: "#fff",
      };
    },
    option: (styles, { data, isDisabled, isSelected, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#EAF1F4"
          : isFocused
          ? "#FFF"
          : "null",
        color: isDisabled
          ? "rgba(51,71,91,0.4)"
          : isSelected
          ? "rgba(51,71,91,0.8)"
          : isFocused
          ? "rgba(51,71,91,0.8)"
          : "rgba(51,71,91,0.4)",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: `nova, sans-serif`,
        fontSize: "14px",
        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && isSelected ? "#EAF1F4" : "rgba(193,204,211,0.5)",
        },
      };
    },
    singleValue: (styles) => ({
      ...styles,
      fontSize: "14px",
      color: "rgba(51,71,91,0.8)",
      fontFamily: `"Open Sans", sans-serif`,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "rgba(51,71,91,0.4)",
      fontSize: "14px",
    }),
  };
};

export default DropdownStyles;
