import {
  REQUEST_DASHBOARD_DATA,
  REQUEST_GROUPLIST_DATA,
  REQUEST_GROUPWISE_DATA,
} from "../../utils/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function getDashboardData(value) {
  return {
    type: REQUEST_DASHBOARD_DATA,
    payload: value,
  };
}

export function getGroupwiseData(value) {
  return {
    type: REQUEST_GROUPWISE_DATA,
    payload: value,
  };
}

export function getGroupList() {
  return {
    type: REQUEST_GROUPLIST_DATA,
  };
}



export const actions = {
  getDashboardData,
  getGroupList,
  getGroupwiseData,
};
