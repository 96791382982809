//Constants
import {
  REQUEST_TABLE_DATA,
  REQUEST_TABLE_DATA_SUCCESS,
  REQUEST_TABLE_DATA_FAILURE,
  REQUEST_TABLE_DATA_BY_DATE,
  REQUEST_TABLE_DATA_BY_DATE_SUCCESS,
  REQUEST_TABLE_DATA_BY_DATE_FAILURE,
  SELECT_GROUP,
  SELECT_GROUP_SUCCESS,
  SELECT_GROUP_FAILURE,
  LOGIN_PROFILE_CREATED_RESET
} from "../../utils/constants";

import { createNotification } from "../../utils/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleTableDataRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const handleTableDataRequestSuccess = (state, action) => {
  const {
    payload: { data }
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      data: data.data
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const handleTableDataRequestFailure = (state, action) => {
  const {
    payload: { response }
  } = action;
  let token = localStorage.getItem("access_token");
  if (token === null) {
    return {
      ...state,
      isLoading: false,
      data: null
    };
  }
  return {
    ...state,
    isLoading: false,
    data: null
  };
};

export const handleTableDataByDateRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const handleTableDataByDateRequestSuccess = (state, action) => {
  const {
    payload: { data }
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      data: data.data
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const handleTableDataByDateRequestFailure = (state, action) => {
  const {
    payload: { response }
  } = action;
  let token = localStorage.getItem("access_token");
  if (token === null) {
    return {
      ...state,
      isLoading: false,
      data: null
    };
  }

  return {
    ...state,
    isLoading: false,
    data: null
  };
};

export const handleSelectGroupRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const handleSelectGroupRequestSuccess = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: false,
    selectedGroup: payload
  };
};

export const handleSelectGroupRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    selectedGroup: null
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [REQUEST_TABLE_DATA]: handleTableDataRequest,
  [REQUEST_TABLE_DATA_SUCCESS]: handleTableDataRequestSuccess,
  [REQUEST_TABLE_DATA_FAILURE]: handleTableDataRequestFailure,
  [REQUEST_TABLE_DATA_BY_DATE]: handleTableDataByDateRequest,
  [REQUEST_TABLE_DATA_BY_DATE_SUCCESS]: handleTableDataByDateRequestSuccess,
  [REQUEST_TABLE_DATA_BY_DATE_FAILURE]: handleTableDataByDateRequestFailure,
  [SELECT_GROUP]: handleSelectGroupRequest,
  [SELECT_GROUP_SUCCESS]: handleSelectGroupRequestSuccess,
  [SELECT_GROUP_FAILURE]: handleSelectGroupRequestFailure,
  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest
};

// default initial state
const initialState = {
  isLoading: false,
  selectedGroup: undefined,
  data: undefined,
};

export default function tableReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
