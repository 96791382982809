// ------------------------------------
// Constants
// ------------------------------------

//Login
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE";
export const LOGIN_REDIRECT_RESET = "LOGIN_REDIRECT_RESET";
export const LOGIN_PROFILE_CREATED_RESET = "LOGIN_PROFILE_CREATED_RESET";
export const MODIFY_AUTH_DATA = "MODIFY_AUTH_DATA";
//Edit Email
export const EDIT_REQUEST = "EDIT_REQUEST";
export const EDIT_REQUEST_SUCCESS = "EDIT_REQUEST_SUCCESS";
export const EDIT_REQUEST_FAILURE = "EDIT_REQUEST_FAILURE";

//Register
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_REQUEST_SUCCESS = "REGISTER_REQUEST_SUCCESS";
export const REGISTER_REQUEST_FAILURE = "REGISTER_REQUEST_FAILURE";
export const REGISTER_RESET = "REGISTER_RESET";

//Verify User
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_REQUEST_SUCCESS = "VERIFY_REQUEST_SUCCESS";
export const VERIFY_REQUEST_FAILURE = "VERIFY_REQUEST_FAILURE";
export const VERIFY_RESET = "VERIFY_RESET";

//Verify invite
export const VERIFY_INVITATION_REQUEST = "VERIFY_INVITATION_REQUEST";
export const VERIFY_INVITATION_REQUEST_SUCCESS =
  "VERIFY_INVITATION_REQUEST_SUCCESS";
export const VERIFY_INVITATION_REQUEST_FAILURE =
  "VERIFY_INVITATION_REQUEST_FAILURE";
export const INVITATION_RESET = "INVITATION_RESET";

//Checking Token
export const CHECK_TOKEN = "CHECK_TOKEN";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_FAILURE = "CHECK_TOKEN_FAILURE";

//Dashboard
export const REQUEST_DASHBOARD_DATA = "REQUEST_DASHBOARD_DATA";
export const REQUEST_DASHBOARD_DATA_SUCCESS = "REQUEST_DASHBOARD_DATA_SUCCESS";
export const REQUEST_DASHBOARD_DATA_FAILURE = "REQUEST_DASHBOARD_DATA_FAILURE";

export const REQUEST_GROUPLIST_DATA = "REQUEST_GROUPLIST_DATA";
export const REQUEST_GROUPLIST_DATA_SUCCESS = "REQUEST_GROUPLIST_DATA_SUCCESS";
export const REQUEST_GROUPLIST_DATA_FAILURE = "REQUEST_GROUPLIST_DATA_FAILURE";

export const REQUEST_GROUPWISE_DATA = "REQUEST_GROUPWISE_DATA";
export const REQUEST_GROUPWISE_DATA_SUCCESS = "REQUEST_GROUPWISE_DATA_SUCCESS";
export const REQUEST_GROUPWISE_DATA_FAILURE = "REQUEST_GROUPWISE_DATA_FAILURE";

export const SELECT_GROUP = "SELECT_GROUP";
export const SELECT_GROUP_SUCCESS = "SELECT_GROUP_SUCCESS";
export const SELECT_GROUP_FAILURE = "SELECT_GROUP_FAILURE";

//Table
export const REQUEST_TABLE_DATA = "REQUEST_TABLE_DATA";
export const REQUEST_TABLE_DATA_SUCCESS = "REQUEST_TABLE_DATA_SUCCESS";
export const REQUEST_TABLE_DATA_FAILURE = "REQUEST_TABLE_DATA_FAILURE";

export const REQUEST_TABLE_DATA_BY_DATE = "REQUEST_TABLE_DATA_BY_DATE";
export const REQUEST_TABLE_DATA_BY_DATE_SUCCESS =
  "REQUEST_TABLE_DATA_BY_DATE_SUCCESS";
export const REQUEST_TABLE_DATA_BY_DATE_FAILURE =
  "REQUEST_TABLE_DATA_BY_DATE_FAILURE";

//Forgot Password
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_REQUEST_SUCCESS =
  "FORGOT_PASSWORD_REQUEST_SUCCESS";
export const FORGOT_PASSWORD_REQUEST_FAILURE =
  "FORGOT_PASSWORD_REQUEST_FAILURE";

//Reset Password
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_STATE_RESET = "RESET_PASSWORD_STATE_RESET";

//Resend Verification mail
export const RESEND_VERIFICATION_MAIL = "RESEND_VERIFICATION_MAIL";
export const RESEND_VERIFICATION_MAIL_SUCCESS =
  "RESEND_VERIFICATION_MAIL_SUCCESS";
export const RESEND_VERIFICATION_MAIL_FAILURE =
  "RESEND_VERIFICATION_MAIL_FAILURE";

//Change Password
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_REQUEST_SUCCESS =
  "CHANGE_PASSWORD_REQUEST_SUCCESS";
export const CHANGE_PASSWORD_REQUEST_FAILURE =
  "CHANGE_PASSWORD_REQUEST_FAILURE";

//User Profile
export const REQUEST_USER_PROFILE = "REQUEST_USER_PROFILE";
export const REQUEST_USER_PROFILE_SUCCESS = "REQUEST_USER_PROFILE_SUCCESS";
export const REQUEST_USER_PROFILE_FAILURE = "REQUEST_USER_PROFILE_FAILURE";
export const CREATE_USER_PROFILE = "CREATE_USER_PROFILE";
export const CREATE_USER_PROFILE_SUCCESS = "CREATE_USER_PROFILE_SUCCESS";
export const CREATE_USER_PROFILE_FAILURE = "CREATE_USER_PROFILE_FAILURE";

//Get Activity Screenshots lists
export const GET_SCREENSHOT_LIST = "GET_SCREENSHOT_LIST";
export const GET_SCREENSHOT_LIST_SUCCESS = "GET_SCREENSHOT_LIST_SUCCESS";
export const GET_SCREENSHOT_LIST_FAILURE = "GET_SCREENSHOT_LIST_FAILURE";
export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const GET_STRIPE_KEY = "GET_STRIPE_KEY";
export const GET_STRIPE_KEY_SUCCESS = "GET_STRIPE_KEY_SUCCESS";
export const GET_STRIPE_KEY_FAILURE = "GET_STRIPE_KEY_FAILURE";

export const SAVE_STRIPE_TOKEN = "SAVE_STRIPE_TOKEN";
export const SAVE_STRIPE_TOKEN_SUCCESS = "SAVE_STRIPE_TOKEN_SUCCESS";
export const SAVE_STRIPE_TOKEN_FAILURE = "SAVE_STRIPE_TOKEN_FAILURE";

export const RETRIEVE_PAYMENT_SOURCE_INFO = "RETRIEVE_PAYMENT_SOURCE_INFO";
export const RETRIEVE_PAYMENT_SOURCE_INFO_SUCCESS =
  "RETRIEVE_PAYMENT_SOURCE_INFO_SUCCESS";
export const RETRIEVE_PAYMENT_SOURCE_INFO_FAILURE =
  "RETRIEVE_PAYMENT_SOURCE_INFO_FAILURE";

export const REMOVE_PAYMENT_SOURCE = "REMOVE_PAYMENT_SOURCE";
export const REMOVE_PAYMENT_SOURCE_SUCCESS = "REMOVE_PAYMENT_SOURCE_SUCCESS";
export const REMOVE_PAYMENT_SOURCE_FAILURE = "REMOVE_PAYMENT_SOURCE_FAILURE";

// Subscription
export const GET_SUBSCRIPTION_PLAN = "GET_SUBSCRIPTION_PLAN";
export const GET_SUBSCRIPTION_PLAN_SUCCESS = "GET_SUBSCRIPTION_PLAN_SUCCESS";
export const GET_SUBSCRIPTION_PLAN_FAILURE = "GET_SUBSCRIPTION_PLAN_FAILURE";
export const UPDATE_SUBSCRIPTION_PLAN = "UPDATE_SUBSCRIPTION_PLAN";
export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS =
  "UPDATE_SUBSCRIPTION_PLAN_SUCCESS";
export const UPDATE_SUBSCRIPTION_PLAN_FAILURE =
  "UPDATE_SUBSCRIPTION_PLAN_FAILURE";
export const CANCEL_PLAN = "CANCEL_PLAN";
export const PAUSE_SUBSCRIPTION = "PAUSE_SUBSCRIPTION";
export const PAUSE_SUBSCRIPTION_SUCCESS = "PAUSE_SUBSCRIPTION_SUCCESS";
export const PAUSE_SUBSCRIPTION_FAILURE = "PAUSE_SUBSCRIPTION_FAILURE";
