import { getSubscriptionPlan } from "./subscriptionActions";

const paddle = window.Paddle;

export const initializePaddle = (initiateAfterCheckoutLoader) => {
  process.env.REACT_APP_DEBUG && paddle.Environment.set("sandbox");
  paddle.Setup({
    vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID),
    eventCallback: (data) => {
        if(data?.event === "Checkout.Close" && data.eventData?.checkout.completed) {
          initiateAfterCheckoutLoader(data?.checkoutData)
        }
    },
  });
};

export const openModal = (url) => {
  let doFetchPayment = true
  getSubscriptionPlan(doFetchPayment);
  paddle.Checkout.open({
    override: url,
  });
};

export const paddleCheckout = (id, name, userMail) => {
  paddle.Checkout.open({
    method: "overlay", // set to `inline`
    product: parseInt(id), // replace with a product ID or plan ID
    allowQuantity: false,
    disableLogout: true,
    email: userMail,
    passthrough: {
      plan_name: name,
    },
  });
};
